import { IGlobalState } from './types';
import {
  STORAGE_BUTTONS,
  STORAGE_CURRENT_DEVICE,
  STORAGE_SPEED,
  STORAGE_THEME,
  STORAGE_USER_INFO,
  STORAGE_USER_SETTINGS,
} from '../resources/storageVariables';
import { getLocalStorage } from '../resources/functions';
import { IUser, IUserSettings } from '../resources/types/user';
import { ESpeed, IButton, TTheme } from '../resources/types/app';

const defaultButtons: IButton[] = [
  { name: 'engine', group: 'main' },
  { name: 'trunk', group: 'small' },
  { name: 'block', group: 'small' },
  { name: 'heat', group: 'small' },
  { name: 'search', group: 'small' },
  { name: 'dashcam', group: 'unused' },
];

const initialState: IGlobalState = {
  app: {
    socket: null,
    buttons: getLocalStorage<IButton[]>(STORAGE_BUTTONS) || defaultButtons,
    speed: getLocalStorage<ESpeed>(STORAGE_SPEED) || ESpeed.CAN,
    theme: getLocalStorage<TTheme>(STORAGE_THEME) || 'light',
    isInit: false,
  },
  user: {
    auth: getLocalStorage<IUser>(STORAGE_USER_INFO),
    info: null,
    settings: getLocalStorage<IUserSettings>(STORAGE_USER_SETTINGS),
  },
  alert: {
    message: '',
    type: 'success',
  },
  devicesInfo: {
    list: [],
    info: null,
    events: {
      data: [],
      meta: {
        nextPage: '1',
        total: 0,
      },
    },
    settings: null,
    curDeviceId: getLocalStorage(STORAGE_CURRENT_DEVICE),
    socketEvents: {
      alarm: null,
    },
    phones: {
      balanceCommand: '',
      balanceInterval: 0,
      firstNumber: '',
      secondNumber: '',
      sosNumber: '',
    },
  },
  loadings: {
    engine: false,
    engineBlock: false,
    trunk: false,
    heat: false,
    findCar: false,
    secure: false,
    service: false,
    dashcam: false,
  },
  tracking: {},
  schedule: {
    enable: false,
  },
};

export default initialState;
