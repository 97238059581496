import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Typography from '../Typography';
import ChevronIconDown from '../../icons/ChevronDownIcon';
import { Props } from './interface';

import './SelectDialog.scss';
import DialogList from '../DialogList';

const SelectDialog = ({ disabled, label, onChange, items }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const toggle = useCallback(() => {
    setDialogOpen(!dialogOpen);
  }, [dialogOpen, setDialogOpen]);

  const onClick = useCallback(
    (value: any) => {
      toggle();
      onChange(value);
    },
    [onChange, toggle]
  );

  return (
    <div
      className={classNames(
        'SelectDialog',
        disabled && 'SelectDialog-disabled'
      )}
      onClick={toggle}
    >
      <Typography size={14} color='main'>
        {label}
      </Typography>
      <ChevronIconDown />
      <DialogList
        open={dialogOpen}
        onClose={toggle}
        items={items}
        onChange={onClick}
      />
    </div>
  );
};

export default SelectDialog;
