import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Props } from './interface';
import './Map.scss';

const Map = (props: Props) => {
  const { center, children, className } = props;

  return (
    <MapContainer
      className={className}
      center={center}
      zoom={13}
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {children}
    </MapContainer>
  );
};

export default Map;
