import React from 'react';
import Typography from '../../components/Typography';
import Switch from '../../components/Switch';
import ListItem from '../../components/ListItem';
import usePending from '../../hooks/usePending';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import { useTranslation } from 'react-i18next';

const selectAutostartCondition = (state: IGlobalState) =>
  state.devicesInfo.info?.params?.autostart.condition;

const AutostartToggle = () => {
  const autostartCondition = useSelector(selectAutostartCondition);

  const { t } = useTranslation();

  const [pending, onFetch] = usePending(async () =>
    DeviceSettingsController.setAutostartCondition(
      autostartCondition === 'off' ? 'time' : 'off'
    )
  );

  return (
    <ListItem>
      <Typography size={16} color='main'>
        {t('heatWorkTime.autostartByCondition')}
      </Typography>
      <Switch
        disabled={pending}
        checked={autostartCondition !== 'off'}
        onChange={onFetch}
      />
    </ListItem>
  );
};

export default AutostartToggle;
