import React from 'react';
import classNames from 'classnames';
import { Props } from './interface';

import './Grid.scss';

function Grid<T>({
  component,
  children,
  container,
  item,
  direction,
  align,
  justify,
  className,
  ...props
}: Props<T>) {
  const Component = component || 'div';

  const cn = classNames('Grid', className, {
    'Grid-container': container,
    'Grid-item': item,
    [`Grid-direction-${direction}`]: direction,
    [`Grid-justify-${justify}`]: justify,
    [`Grid-align-${align}`]: align,
  });

  // @ts-ignore
  const injectedProps: T = props;

  return (
    <Component {...injectedProps} className={cn}>
      {children}
    </Component>
  );
}

export default Grid;
