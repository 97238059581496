import React, { useCallback } from 'react';
import ListItem from '../ListItem';
import Typography from '../Typography';
import Dialog from '../Dialog';
import { Props } from './interface';
import './DialogList.scss';

const DialogList = (props: Props) => {
  const { onChange, items, onClose, open } = props;

  const onClick = useCallback(
    (value: any) => () => {
      onClose();
      onChange(value);
    },
    [onChange, onClose]
  );

  return (
    <Dialog
      className='DialogList'
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={288}
      scroll='body'
    >
      {items.map(item => (
        <ListItem
          onClick={onClick(item.value)}
          key={item.value}
          className='DialogList-item'
        >
          <Typography align='center' component='div'>
            {item.label}
          </Typography>
        </ListItem>
      ))}
    </Dialog>
  );
};

export default DialogList;
