import { useCallback, useContext, useState } from 'react';
import ClickDelayContext from '../components/ClickDelayLoader';

const useClickDelay = () => {
  const clickDelayContext = useContext(ClickDelayContext);

  const [delay, setDelay] = useState<null | number>(null);

  const onMouseDown = useCallback(
    (fn: () => void) => () => {
      clickDelayContext.setVisible(true);
      setDelay(
        // @ts-ignore
        setTimeout(() => {
          clickDelayContext.setVisible(false);
          fn();
        }, 1000)
      );
    },
    [delay]
  );

  const stopDelay = useCallback(() => {
    if (delay) {
      clickDelayContext.setVisible(false);
      clearTimeout(delay);
      setDelay(null);
    }
  }, [delay]);

  const onMouseUp = stopDelay;
  const onMouseLeave = stopDelay;

  return useCallback(
    (fn: () => void) => {
      return { onMouseDown: onMouseDown(fn), onMouseUp, onMouseLeave };
    },
    [onMouseDown, onMouseUp, onMouseLeave]
  );
};

export default useClickDelay;
