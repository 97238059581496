import React, { useCallback } from 'react';
import ListItem from '../ListItem';
import { Props } from './interface';
import Typography from '../Typography';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import {
  IScheduleDay,
  TScheduleKeys,
  TScheduleState,
} from '../../resources/types/schedule';
import Grid from '../Grid';
import Switch from '../Switch';
import './ScheduleDay.scss';
import Select from '../Select';
import { hoursOptions, minutesOptions } from '../../resources/constVariables';
import ScheduleController from '../../redux/controllers/ScheduleController';
import classNames from 'classnames';

const popoverProps = { className: 'ScheduleDay-timeSelectPopover' };

const ScheduleDay = (props: Props) => {
  const { day, onChange } = props;

  const daySettings = useSelector(({ schedule }: IGlobalState) => {
    const settings: Record<string, IScheduleDay> = {};

    for (const scheduleKey in schedule) {
      if (scheduleKey.includes(day)) {
        const scheduleDay = schedule[scheduleKey as keyof TScheduleState];

        if (typeof scheduleDay !== 'boolean' && scheduleDay) {
          settings[scheduleKey] = scheduleDay;
        }
      }
    }

    return settings;
  }, shallowEqual);

  const { t } = useTranslation();

  const createSelectHandler = useCallback(
    (
        dayTime: TScheduleKeys,
        daySettingValue: IScheduleDay,
        isHours?: boolean
      ) =>
      (value: string) => {
        let time = daySettingValue.time;

        if (isHours) {
          time = `${value}${time.slice(2, 4)}`;
        } else {
          time = `${time.slice(0, 2)}${value}`;
        }

        ScheduleController.setScheduleSettings({
          [dayTime]: { ...daySettingValue, time },
        });

        onChange();
      },
    [onChange]
  );

  const createSwitchHandler = useCallback(
    (dayTime: TScheduleKeys, daySettingValue: IScheduleDay) => () => {
      ScheduleController.setScheduleSettings({
        [dayTime]: { ...daySettingValue, enable: !daySettingValue.enable },
      });

      onChange();
    },
    [onChange]
  );

  return (
    <ListItem className='ScheduleDay'>
      <Typography className='ScheduleDay-title' size={16} color='main'>
        {t(`days.${day}` as any)}
      </Typography>
      {Object.keys(daySettings).map(key => {
        const dayTime = key as TScheduleKeys;
        const daySettingValue = daySettings[dayTime];

        const hour = daySettingValue.time.slice(0, 2);
        const minute = daySettingValue.time.slice(2, 4);

        return (
          <Grid
            container
            key={dayTime}
            className='ScheduleDay-dayTime'
            justify='space-between'
            align='center'
          >
            <Grid container direction='column'>
              <div
                className={classNames(
                  'ScheduleDay-timeSelect',
                  daySettingValue.enable && 'ScheduleDay-timeSelect-enabled'
                )}
              >
                <Select
                  popoverProps={popoverProps}
                  withoutChevron
                  variant='text'
                  value={hour}
                  onChange={createSelectHandler(dayTime, daySettingValue, true)}
                  options={hoursOptions}
                />
                <span>:</span>
                <Select
                  popoverProps={popoverProps}
                  withoutChevron
                  variant='text'
                  value={minute}
                  onChange={createSelectHandler(dayTime, daySettingValue)}
                  options={minutesOptions}
                />
              </div>
              {daySettingValue.enable && (
                <Typography
                  className='ScheduleDay-timeHint'
                  size={11}
                  color='second'
                >
                  {t('schedule.timeHint')}
                </Typography>
              )}
            </Grid>
            <Switch
              checked={daySettingValue.enable}
              onChange={createSwitchHandler(dayTime, daySettingValue)}
            />
          </Grid>
        );
      })}
    </ListItem>
  );
};

export default ScheduleDay;
