import Controller from './Controller';
import { handleDefaultErrors } from '../../resources/functions';
import axios from '../../lib/axios';
import {
  GET_TRACKING_HISTORY,
  GET_TRACKING_STATS,
  SET_CURRENT_COORDS,
} from '../actionTypes';
import { IGlobalState } from '../types';

class TrackingController extends Controller {
  async getCurrent() {
    try {
      const deviceId = this.getState().devicesInfo.curDeviceId;
      const response = await axios.get('/devices/map/current', {
        params: { deviceId },
      });
      this.dispatch({
        type: SET_CURRENT_COORDS,
        payload: response.data,
      });
    } catch (e) {
      handleDefaultErrors(e);
    }
  }

  async getExtended(from: string, to: string) {
    try {
      const deviceId = this.getState().devicesInfo.curDeviceId;
      const response = await axios.get('/devices/map/tracking_extended', {
        params: { deviceId, from, to },
      });
      this.dispatch({
        type: GET_TRACKING_HISTORY,
        payload: response.data,
      });
    } catch (e) {
      handleDefaultErrors(e);
    }
  }

  async getStats(from: string, to: string) {
    try {
      const deviceId = this.getState().devicesInfo.curDeviceId;
      const response = await axios.get('/devices/map/stats', {
        params: { deviceId, from, to },
      });
      this.dispatch({
        type: GET_TRACKING_STATS,
        payload: response.data,
      });
    } catch (e) {
      handleDefaultErrors(e);
    }
  }
}

export const selectTracking = (state: IGlobalState) => state.tracking;

export default new TrackingController();
