import React, { useCallback, useEffect, useState } from 'react';
import Grid from '../../components/Grid';
import RangeDatePicker from '../../components/RangeDatePicker';
import Typography from '../../components/Typography';
import Svg from '../../components/Svg';
import moment from 'moment';
import {
  IHistoryPageListFiltersItemProps,
  IHistoryPageListFiltersProps,
} from './interface';
import classNames from 'classnames';
import Button from '../../components/Button';

import blockIcon from '../../img/carControl/block.svg';
import engineIcon from '../../img/deviceInfo/engine.svg';
import doorIcon from '../../img/deviceInfo/door.svg';
import alertIcon from '../../img/icons/alert2.svg';
import punchIcon from '../../img/deviceInfo/punch.svg';

import {
  checkSameDate,
  setDayEnd,
  setDayStart,
} from '../../resources/functions';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import DevicesInfoController from '../../redux/controllers/DevicesInfoController';
import {
  TDeviceEventsFilters,
  THistoryEvents,
} from '../../resources/types/devices';
import Wrapper from '../../components/Wrapper';
import HistoryEvents from './HistoryEvents';

import './HistoryPage.scss';
import { useTranslation } from 'react-i18next';
import useTimeZone from '../../hooks/useTimeZone';

const icons: { [key in THistoryEvents]: string } = {
  lock: blockIcon,
  engine: engineIcon,
  doors: doorIcon,
  event: alertIcon,
  alarm: punchIcon,
};

const HistoryPageListFiltersItem = ({
  type,
  active,
  onClick,
}: IHistoryPageListFiltersItemProps) => (
  <Grid
    align='center'
    className={classNames(
      'HistoryPageListFiltersItem',
      `HistoryPageListFiltersItem-${type}`,
      active &&
        `HistoryPageListFiltersItem-active HistoryPageListFiltersItem-${type}-active`
    )}
    component={Button}
    container
    item
    justify='center'
    onClick={onClick}
    variant='text'
  >
    <Svg src={icons[type]} />
  </Grid>
);

const HistoryPageListFilters = ({
  activeFilters,
  onChange,
}: IHistoryPageListFiltersProps) => {
  const filters: THistoryEvents[] = [
    'lock',
    'engine',
    'doors',
    'event',
    'alarm',
  ];

  return (
    <Grid className='HistoryPageListFilters' container>
      {filters.map(filter => (
        <HistoryPageListFiltersItem
          key={filter}
          active={activeFilters.includes(filter)}
          onClick={() => onChange(filter)}
          type={filter}
        />
      ))}
    </Grid>
  );
};

const HistoryPage = () => {
  const { t } = useTranslation();

  const [from, setFrom] = useState<Date | null>(() => setDayStart().toDate());
  const [to, setTo] = useState<Date | null>(() => setDayEnd().toDate());
  const onChangeRange = useCallback((from: Date | null, to: Date | null) => {
    setFrom(from);
    setTo(to);
  }, []);

  const setUtcDate = useTimeZone();

  const [filters, setFilters] = useState<THistoryEvents[]>([]);
  const onChangeFilters = useCallback(
    (filter: TDeviceEventsFilters) => {
      const nextFilters = [...filters];
      const matchIndex = nextFilters.indexOf(filter);
      if (matchIndex !== -1) {
        nextFilters.splice(matchIndex, 1);
      } else {
        nextFilters.push(filter);
      }
      setFilters(nextFilters);
    },
    [filters]
  );

  const deviceId = useSelector(
    ({ devicesInfo }: IGlobalState) => devicesInfo.curDeviceId
  );

  const getEvents = useCallback(() => {
    if (deviceId) {
      const utcFrom = setUtcDate(from);
      const utcTo = setUtcDate(to);

      if (utcFrom && utcTo) {
        DevicesInfoController.getDeviceEvents({
          deviceId,
          from: utcFrom,
          to: utcTo,
          filters: filters.join(','),
          limit: 100,
        });
      }
    }
  }, [deviceId, from, to, filters, setUtcDate]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <Grid className='HistoryPage' container direction='column'>
      <Wrapper>
        <RangeDatePicker
          endDate={to}
          onChange={onChangeRange}
          startDate={from}
        />
        <Typography
          className='HistoryPage-period'
          color='main'
          component='p'
          size={18}
          weight={500}
        >
          {t('history.historyFor')} {moment(from || undefined).format('DD.MM')}{' '}
          {to &&
            from &&
            !checkSameDate(from, to) &&
            `- ${moment(to).format('DD.MM')}`}
        </Typography>
      </Wrapper>
      <HistoryPageListFilters
        activeFilters={filters}
        onChange={onChangeFilters}
      />
      <HistoryEvents />
    </Grid>
  );
};

export default HistoryPage;
