import React from 'react';
import { IBlinkProps } from './interface';
import classNames from 'classnames';

import './Blink.scss';

const Blink = ({ children, active, className, component }: IBlinkProps) => {
  const Component = component || 'div';

  return (
    <Component
      className={classNames('Blink', className, active && 'Blink-active')}
    >
      {children}
    </Component>
  );
};

export default Blink;
