import React from 'react';
import SelectDialog from '../../components/SelectDialog/SelectDialog';
import { autostartVoltageThresholdValues } from '../../resources/constVariables';
import { IGlobalState } from '../../redux/types';
import { useSelector } from 'react-redux';
import usePending from '../../hooks/usePending';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { TAutostartVoltageThresholdValues } from '../../resources/types/devices';
import { useTranslation } from 'react-i18next';

const selectVoltage = (state: IGlobalState) =>
  state.devicesInfo.info?.params?.autostart.voltageThreshold;

const VoltageSelect = () => {
  const voltage = useSelector(selectVoltage);

  const { t } = useTranslation();

  const [
    pending,
    onFetch,
  ] = usePending((value: TAutostartVoltageThresholdValues) =>
    DeviceSettingsController.setVoltageThreshold(value)
  );

  return (
    <SelectDialog
      items={autostartVoltageThresholdValues.map(item => ({
        label: `${item} ${t('common.V')}`,
        value: item,
      }))}
      label={`${t('heatWorkTime.lowVoltageLevel')} (${voltage || 0})`}
      onChange={onFetch}
      disabled={pending}
    />
  );
};

export default VoltageSelect;
