import {
  calculatePopoverLeft,
  calculatePopoverTop,
  DEFAULT_ANCHOR_ORIGIN,
} from './utils';
import React, { useMemo, useState } from 'react';
import { Props } from './interface';
import Modal from '../Modal';
import Paper from '../Paper';

import './Popover.scss';
import classNames from 'classnames';

const Popover = ({
  anchorRef,
  anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
  transformOrigin = DEFAULT_ANCHOR_ORIGIN,
  children,
  style,
  className,
  ...modalProps
}: Props) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);

  const position = useMemo(() => {
    if (anchorRef.current && popoverRef) {
      return {
        top: calculatePopoverTop(
          popoverRef,
          anchorRef.current,
          anchorOrigin?.vertical,
          transformOrigin?.vertical
        ),
        left: calculatePopoverLeft(
          popoverRef,
          anchorRef.current,
          anchorOrigin?.horizontal,
          transformOrigin?.horizontal
        ),
      };
    }

    return {
      top: 0,
      left: 0,
    };
  }, [anchorRef, popoverRef, anchorOrigin, transformOrigin]);

  return (
    <Modal {...modalProps}>
      <Paper
        className={classNames('Popover', className)}
        innerRef={(ref: HTMLElement) => setPopoverRef(ref)}
        style={{ ...style, ...position }}
      >
        {children}
      </Paper>
    </Modal>
  );
};

export default Popover;
