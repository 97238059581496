import React from 'react';
import { Props } from './interface';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/Dialog';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import './EngineLockConfirmDialog.scss';
import DeviceControlController from '../../redux/controllers/DeviceControlController';
import { useTranslation } from 'react-i18next';

const EngineLockConfirmDialog = (props: Props) => {
  const { open, onClose, isUnlocking, openConfirmPasswordDialog } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(() => {
    if (isUnlocking) {
      openConfirmPasswordDialog();
    } else {
      DeviceControlController.toggleBlock();
    }
    onClose();
  }, [isUnlocking, openConfirmPasswordDialog, onClose]);

  return (
    <Dialog open={open} onClose={onClose} className='EngineLockConfirmDialog'>
      <DialogTitle>
        <Typography size={16} weight={700}>
          {t('common.attention')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography color='third'>
          {t('common.confirmQuestion')}{' '}
          {isUnlocking ? t('common.unblock') : t('common.block')}{' '}
          {t('common.engine')}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <div className='EngineLockConfirmDialog-buttons'>
          <Button onClick={onClose}>{t('common.no')}</Button>
          <Button onClick={handleSubmit}>{t('common.yes')}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EngineLockConfirmDialog;
