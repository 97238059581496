import React, { useCallback, useEffect, useRef } from 'react';

import { Props, TNavBarLinkIcon } from './interface';
import Grid from '../Grid';
import { NavLink, useLocation } from 'react-router-dom';
import Typography from '../Typography';
import Svg from '../Svg';

import remoteIcon from '../../img/remote.svg';
import clockIcon from '../../img/navBar/clock.svg';
import geolocationIcon from '../../img/navBar/geolocation.svg';
import gearIcon from '../../img/navBar/gear.svg';

import './NavBarLink.scss';

const icons: { [key in TNavBarLinkIcon]: string } = {
  control: remoteIcon,
  tracking: geolocationIcon,
  history: clockIcon,
  settings: gearIcon,
};

const NavBarLink = ({ icon, label, to, isActive, exact, onChange }: Props) => {
  const ref = useRef(null);

  const handleClick = useCallback(() => {
    onChange(ref);
  }, [onChange, ref]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === to || location.pathname.includes('/settings')) {
      onChange(ref);
    }
  }, [location.pathname]);

  return (
    <Grid
      activeClassName='NavBarLink-active'
      className='NavBarLink'
      component={NavLink}
      exact={exact}
      innerRef={ref}
      isActive={isActive}
      item
      onChange={handleClick}
      to={to}
    >
      <Grid
        align='center'
        className='NavBarLink-content'
        container
        direction='column'
        justify='center'
      >
        <Svg src={icons[icon]} className='NavBarLink-icon' />
        <Typography className='NavBarLink-label' color='second' size={10}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NavBarLink;
