import React, { useCallback, useState } from 'react';
import Logo from '../../components/Logo';
import Grid from '../../components/Grid';
import AuthForm from '../../components/AuthForm';
import useFormInput from '../../hooks/useFormInput';
import { ERecoveryState } from './interface';
import UserController from '../../redux/controllers/UserController';
import { Link, RouteComponentProps } from 'react-router-dom';
import FormElement from '../../components/FormElement';
import AlertController from '../../redux/controllers/AlertController';
import Typography from '../../components/Typography';
import { useTranslation } from 'react-i18next';

const PasswordRecovery = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();

  const [state, setState] = useState(ERecoveryState.email);

  const [resetId, setResetId] = useState('');
  const email = useFormInput('');
  const code = useFormInput('');
  const password = useFormInput('');
  const repeatPassword = useFormInput('');

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (state === ERecoveryState.email) {
        UserController.sendResetPasswordCode(email.value, nextResetId => {
          setState(ERecoveryState.code);
          setResetId(nextResetId);
        });
      } else if (state === ERecoveryState.code) {
        UserController.checkResetPasswordCode(resetId, code.value, () => {
          setState(ERecoveryState.recovery);
        });
      } else {
        if (password.value === repeatPassword.value) {
          UserController.resetPassword(
            password.value,
            resetId,
            code.value,
            () => {
              history.push('/login');
              AlertController.show(
                t('restorePassword.restoreSuccess'),
                'success'
              );
            }
          );
        } else {
          AlertController.show(t('restorePassword.notMatch'), 'error');
        }
      }
    },
    [state, email, code, resetId, password, repeatPassword, t]
  );

  return (
    <Grid
      align='center'
      className='LoginPage'
      container
      direction='column'
      justify='center'
    >
      <Logo />

      <AuthForm
        title={t('restorePassword.title')}
        onSubmit={handleSubmit}
        buttonLabel={t('common.next')}
      >
        {state === ERecoveryState.email && (
          <FormElement
            {...email}
            fullWidth
            label='Email'
            placeholder={`${t('common.enter')} email`}
            required
          />
        )}
        {state === ERecoveryState.code && (
          <FormElement
            {...code}
            fullWidth
            label={t('restorePassword.confirmCode')}
            placeholder={`${t('common.enter')} ${t('restorePassword.code')}`}
            required
          />
        )}
        {state === ERecoveryState.recovery && (
          <>
            <FormElement
              {...password}
              fullWidth
              label={t('common.password')}
              placeholder={`${t('common.enter')} ${t('common.password')}`}
              required
              inputProps={{
                type: 'password',
                autoComplete: 'new-password',
              }}
            />
            <FormElement
              {...repeatPassword}
              fullWidth
              label={t('restorePassword.repeatPassword')}
              placeholder={`${t('common.enter')} ${t('common.password')}`}
              required
              inputProps={{
                type: 'password',
                autoComplete: 'new-password',
              }}
            />
          </>
        )}
      </AuthForm>

      <Grid align='center' container direction='column'>
        <Typography
          color='red'
          component={Link}
          size={18}
          textDecoration='none'
          to='/login'
          weight={600}
        >
          {t('restorePassword.logIn')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PasswordRecovery;
