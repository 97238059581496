import React, { useCallback, useEffect, useState } from 'react';
import Grid from '../../components/Grid';
import SettingsLink from '../../components/SettingsLink';

import './SettingsPage.scss';
import Switch from '../../components/Switch';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import DeviceControlController from '../../redux/controllers/DeviceControlController';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
  const { dashcam, service, loadings } = useSelector((state: IGlobalState) => ({
    dashcam: state.devicesInfo.info?.dashcam,
    service: state.devicesInfo.info?.service,
    loadings: state.loadings,
  }));

  const { t } = useTranslation();

  const [dashcamState, setDashcamState] = useState(false);
  const [serviceState, setServiceState] = useState(false);

  useEffect(() => {
    setDashcamState(Boolean(dashcam));
    setServiceState(Boolean(service));
  }, [dashcam, service]);

  const toggleDashcam = useCallback(() => {
    DeviceControlController.toggleDashcam(dashcamState);
    setDashcamState(prevState => !prevState);
  }, [dashcamState]);

  const toggleService = useCallback(() => {
    DeviceControlController.toggleService(serviceState);
    setServiceState(prevState => !prevState);
  }, [serviceState]);

  return (
    <Grid className='SettingsPage' container direction='column'>
      <SettingsLink icon='info' label={t('settings.commonInfo')} to='/info' />
      <SettingsLink icon='devises' label={t('settings.myCars')} to='/devises' />
      <SettingsLink
        icon='settings'
        label={t('settings.systemSettings')}
        to='/system'
      />
      <SettingsLink icon='app' label={t('settings.appSettings')} to='/app' />
      <SettingsLink
        className='SettingsLink-service'
        icon='wrench'
        label={t('settings.serviceMode')}
        isDiv
        renderAction={props => (
          <Grid container align='center' className='SettingsPage-switchLoader'>
            <Spinner show={loadings.service} />
            <Switch
              {...props}
              checked={serviceState}
              onClick={toggleService}
              disabled={loadings.service}
            />
          </Grid>
        )}
      />
      <SettingsLink
        className='SettingsLink-dashcam'
        icon='dashcam'
        label={t('settings.dashcam')}
        isDiv
        renderAction={props => (
          <Grid container align='center' className='SettingsPage-switchLoader'>
            <Spinner show={loadings.dashcam} />
            <Switch
              {...props}
              checked={dashcamState}
              onClick={toggleDashcam}
              disabled={loadings.dashcam}
            />
          </Grid>
        )}
      />
      <SettingsLink icon='ref' label={t('settings.reference')} to='/ref' />
    </Grid>
  );
};

export default SettingsPage;
