import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainPage from '../../pages/MainPage';
import SettingsPage from '../../pages/SettingsPage';
import SettingsRoutes from '../SettingsRoutes';
import HistoryPage from '../../pages/HistoryPage';

const routes = [
  {
    path: '/',
    component: MainPage,
  },
  {
    path: '/history',
    component: HistoryPage,
  },
  {
    path: '/settings',
    component: SettingsPage,
  },
  {
    path: '/settings/:page',
    component: SettingsRoutes,
  },
  {
    path: '/settings/ref/:type',
    component: SettingsRoutes,
  },
];

const Routes = () => {
  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          component={route.component}
          exact
          path={route.path}
        />
      ))}
    </Switch>
  );
};

export default Routes;
