import React from 'react';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';

import './EngineBlockButton.scss';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../../redux/types';
import classNames from 'classnames';
import useClickDelay from '../../../../hooks/useClickDelay';
import EngineLockConfirmDialog from '../../../../dialogs/EngineLockConfirmDialog';
import EngineLockConfirmPasswordDialog from '../../../../dialogs/EngineLockConfirmPasswordDialog';
import { useTranslation } from 'react-i18next';

const EngineBlockButton = () => {
  const { t } = useTranslation();

  const { blocked, loading, visible } = useSelector(
    ({ devicesInfo, loadings }: IGlobalState) => ({
      blocked: devicesInfo.info?.state?.blocked,
      loading: loadings.engineBlock,
      visible: devicesInfo.info?.params?.app.blockButton,
    })
  );

  const clickDelay = useClickDelay();

  const [engineLockDialogOpen, setEngineLockDialogOpen] = React.useState(false);

  const [
    engineLockConfirmPasswordDialogOpen,
    setEngineLockConfirmPasswordDialogOpen,
  ] = React.useState(false);

  const toggleEngineDialog = React.useCallback(
    () => setEngineLockDialogOpen(prevState => !prevState),
    []
  );

  const toggleEngineConfirmPasswordDialog = React.useCallback(() => {
    setEngineLockConfirmPasswordDialogOpen(prevState => !prevState);
  }, []);

  if (!visible) return null;

  return (
    <Grid
      className={classNames(
        'EngineBlockButton',
        blocked && 'EngineBlockButton-active'
      )}
      container
      justify='center'
    >
      <Button
        {...clickDelay(toggleEngineDialog)}
        loading={loading}
        startIcon='engineBlock'
        variant='outlined'
      >
        {blocked ? t('control.engineUnblock') : t('control.engineBlock')}
      </Button>
      <EngineLockConfirmDialog
        open={engineLockDialogOpen}
        isUnlocking={blocked}
        onClose={toggleEngineDialog}
        openConfirmPasswordDialog={toggleEngineConfirmPasswordDialog}
      />
      <EngineLockConfirmPasswordDialog
        open={engineLockConfirmPasswordDialogOpen}
        onClose={toggleEngineConfirmPasswordDialog}
      />
    </Grid>
  );
};

export default EngineBlockButton;
