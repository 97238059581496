import Controller from './Controller';
import axios from '../../lib/axios';
import { handleDefaultErrors } from '../../resources/functions';
import AlertController from './AlertController';
import LoadingsController from './LoadingsController';
import i18next from '../../lib/i18next';

class DeviceControlController extends Controller {
  async toggleEngine() {
    const engineRunning = this.getState().devicesInfo.info?.state
      ?.engineRunning;

    try {
      LoadingsController.setEngine(true);
      await axios.post(`/devices/engine/${engineRunning ? 'stop' : 'start'}`, {
        deviceId: this.deviceId,
      });
      AlertController.show(i18next.t('common.successSentToDevice'), 'success');
      LoadingsController.setEngine();
    } catch (error) {
      LoadingsController.setEngine();
      handleDefaultErrors(error, () => {
        switch (error.response.status) {
          case 408:
            AlertController.show(
              i18next.t('common.errorSentToDevice'),
              'error'
            );
        }
      });
    }
  }

  async toggleHeat() {
    const heatActive = this.getState().devicesInfo.info?.heat;

    try {
      LoadingsController.setHeat(true);
      await axios.post(`/devices/heat/${heatActive ? 'stop' : 'start'}`, {
        deviceId: this.deviceId,
      });
      AlertController.show(i18next.t('common.successSentToDevice'), 'success');
    } catch (error) {
      LoadingsController.setHeat();
      handleDefaultErrors(error, () => {
        switch (error.response.status) {
          case 408:
            AlertController.show(
              i18next.t('common.errorSentToDevice'),
              'error'
            );
        }
      });
    }
  }

  async toggleSecure() {
    const secureActive = this.getState().devicesInfo.info?.secure;

    try {
      LoadingsController.setSecure(true);
      await axios.post(
        `/devices/secure/${secureActive ? 'disable' : 'enable'}`,
        { deviceId: this.deviceId }
      );
      AlertController.show(i18next.t('common.successSentToDevice'), 'success');
      LoadingsController.setSecure();
    } catch (error) {
      LoadingsController.setSecure();
      handleDefaultErrors(error, () => {
        switch (error.response.status) {
          case 408:
            AlertController.show(
              i18next.t('common.errorSentToDevice'),
              'error'
            );
        }
      });
    }
  }

  async toggleBlock(password?: string, success?: () => void) {
    const blocked = this.getState().devicesInfo.info?.state?.blocked;

    try {
      LoadingsController.setEngineBlock(true);
      await axios.post(`/devices/block/${blocked ? 'disable' : 'enable'}`, {
        deviceId: this.deviceId,
        password,
      });
      if (success) {
        success();
      }
    } catch (error) {
      LoadingsController.setEngineBlock(false, true);
      handleDefaultErrors(error, () => {
        switch (error.response.status) {
          case 408:
            AlertController.show(
              i18next.t('common.errorSentToDevice'),
              'error'
            );
            break;
          case 403:
            AlertController.show(i18next.t('common.wrongPassword'), 'error');
            break;
        }
      });
    }
  }

  async toggleTrunk() {
    try {
      LoadingsController.setTrunk(true);
      await axios.post('/devices/misc/trunk', { deviceId: this.deviceId });
      AlertController.show(i18next.t('common.successSentToDevice'), 'success');
      LoadingsController.setTrunk();
    } catch (error) {
      LoadingsController.setTrunk(false, true);
      handleDefaultErrors(error, () => {
        switch (error.response.status) {
          case 408:
            AlertController.show(
              i18next.t('common.errorSentToDevice'),
              'error'
            );
        }
      });
    }
  }

  async searchCar() {
    try {
      LoadingsController.setFindCar(true);
      await axios.post('/devices/misc/find_car', { deviceId: this.deviceId });
      AlertController.show(i18next.t('common.successSentToDevice'), 'success');
      LoadingsController.setFindCar();
    } catch (error) {
      LoadingsController.setFindCar();
      handleDefaultErrors(error, () => {
        switch (error.response.status) {
          case 408:
            AlertController.show(
              i18next.t('common.errorSentToDevice'),
              'error'
            );
        }
      });
    }
  }

  async toggleDashcam(enable?: boolean) {
    try {
      LoadingsController.setDashcam(true);
      const isEnable = enable ?? this.getState().devicesInfo.info?.dashcam;
      await axios.post(`/devices/dashcam/${isEnable ? 'disable' : 'enable'}`, {
        deviceId: this.deviceId,
      });
      LoadingsController.setDashcam(false);
    } catch (error) {
      LoadingsController.setDashcam(false);
      handleDefaultErrors(error);
    }
  }

  async toggleService(enable?: boolean) {
    try {
      LoadingsController.setService(true);
      await axios.post(`/devices/service/${enable ? 'disable' : 'enable'}`, {
        deviceId: this.deviceId,
      });
      LoadingsController.setService(false);
    } catch (error) {
      LoadingsController.setService(false);
      handleDefaultErrors(error);
    }
  }
}

export default new DeviceControlController();
