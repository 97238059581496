import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '../../components/Grid';
import Logo from '../../components/Logo';
import Typography from '../../components/Typography';
import FormElement from '../../components/FormElement';
import UserController from '../../redux/controllers/UserController';
import { Props } from './interface';
import { ISigninData } from '../../resources/types/user';
import useLegacyState from '../../hooks/useLegacyState';
import AuthForm from '../../components/AuthForm';
import Svg from '../../components/Svg';
import passwordIcon from '../../img/password.svg';

import './LoginPage.scss';
import { useTranslation } from 'react-i18next';

const LoginPage = ({ history }: Props) => {
  const { t } = useTranslation();

  const [signInData, setData] = useLegacyState<ISigninData>({
    login: '',
    password: '',
  });

  const handleChange = (name: keyof ISigninData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setData({ [name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    UserController.signIn(signInData, () => history.push('/'));
  };

  return (
    <Grid
      align='center'
      className='LoginPage'
      container
      direction='column'
      justify='center'
    >
      <Logo />

      <Grid container direction='column' align='center'>
        <AuthForm
          title={t('logIn.title')}
          onSubmit={handleSubmit}
          buttonLabel={t('logIn.logIn')}
        >
          <FormElement
            fullWidth
            label={t('common.login')}
            onChange={handleChange('login')}
            placeholder={`${t('common.enter')} ${t('common.login')}`}
            required
            value={signInData.login}
          />
          <FormElement
            fullWidth
            inputProps={{
              type: 'password',
            }}
            label={t('common.password')}
            onChange={handleChange('password')}
            placeholder={`${t('common.enter')} ${t('common.password')}`}
            required
            value={signInData.password}
          />
        </AuthForm>

        <Link to='/recovery' className='LoginPage-recoveryLink'>
          <Svg src={passwordIcon} />
          <Typography size={16} color='second'>
            {t('logIn.restorePassword')}
          </Typography>
        </Link>
      </Grid>

      <Grid
        align='center'
        container
        direction='column'
        className='LoginPage-footer'
      >
        <Typography color='second' size={14}>
          {t('logIn.dontHaveAccount')}
        </Typography>
        <Typography
          className='LoginPage-link'
          color='red'
          component={Link}
          size={18}
          textDecoration='none'
          to='/registration'
          weight={600}
        >
          {t('logIn.register')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
