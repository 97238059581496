import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../redux/types';
import { useAlert } from 'react-alert';
import AlertController from '../redux/controllers/AlertController';

export default function () {
  const alert = useAlert();
  const { message, type } = useSelector((state: IGlobalState) => state.alert);

  useEffect(() => {
    if (!message) return;

    switch (type) {
      case 'success':
        alert.success(message);
        break;

      case 'error':
        alert.error(message);
        break;

      default:
        alert.show(message);
    }

    AlertController.clear();
  }, [alert, message, type]);
}
