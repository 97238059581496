import React, { useMemo } from 'react';
import { Props } from './interface';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/Dialog';
import Typography from '../../components/Typography';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectTracking } from '../../redux/controllers/TrackingController';
import { secondsToHms } from '../../resources/functions';
import { ITrackingStats } from '../../resources/types/trackingTypes';
import Button from '../../components/Button';
import './TrackingStatsDialog.scss';
import { useTranslation } from 'react-i18next';

const TrackingStatsDialog = (props: Props) => {
  const { open, onClose, date } = props;

  const tracking = useSelector(selectTracking);
  const stats = tracking.stats || ({} as ITrackingStats);

  const { t } = useTranslation();

  const totalTime = useMemo(() => secondsToHms(stats.totalTime), [
    stats.totalTime,
  ]);
  const movingTime = useMemo(() => secondsToHms(stats.movingTime), [
    stats.movingTime,
  ]);
  const distance = useMemo(() => (stats.distance / 1000).toFixed(1), [
    stats.distance,
  ]);

  return (
    <Dialog open={open} onClose={onClose} className='TrackingStatsDialog'>
      <DialogTitle>
        <Typography color='main' size={24} weight={700}>
          {t('maps.statsFor')} {moment(date).format('DD MMM')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography size={16} color='third'>
          {t('maps.total')}:
        </Typography>{' '}
        <Typography size={16} color='main'>
          {totalTime.h} {t('common.h')}. {totalTime.m} {t('common.min')}.
        </Typography>
        <br />
        <Typography size={16} color='third'>
          {t('maps.inMotion')}:
        </Typography>{' '}
        <Typography size={16} color='main'>
          {movingTime.h} {t('common.h')}. {movingTime.m} {t('common.min')}.
        </Typography>
        <br />
        <Typography size={16} color='third'>
          {t('maps.mileage')}:
        </Typography>{' '}
        <Typography size={16} color='main'>
          {distance} {t('common.km')}.
        </Typography>
        <br />
        <Typography size={16} color='third'>
          {t('maps.maxSpeed')}:
        </Typography>{' '}
        <Typography size={16} color='main'>
          {Math.round(stats.maxSpeed)} {t('common.km/h')}
        </Typography>
        <br />
        <Typography size={16} color='third'>
          {t('maps.averageSpeed')}:
        </Typography>{' '}
        <Typography size={16} color='main'>
          {Math.round(stats.averageSpeed)} {t('common.km/h')}
        </Typography>
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrackingStatsDialog;
