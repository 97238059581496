import Controller from './Controller';
import axios from '../../lib/axios';
import { handleDefaultErrors } from '../../resources/functions';
import {
  SET_PHONE_SETTINGS,
  SET_AUTOSTART_PARAMS,
  UPDATE_PARAMS,
} from '../actionTypes';
import {
  IEditDeviceBody,
  ISetPhoneSettings,
  TAutostartConditionValues,
  TAutostartIntervalValues,
  TAutostartTemperatureValues,
  TAutostartTimeValues,
  TAutostartVoltageThresholdValues,
  THeatTime,
} from '../../resources/types/devices';
import AlertController from './AlertController';

class DeviceSettingsController extends Controller {
  async edit(body: IEditDeviceBody, success?: () => void) {
    try {
      await axios.post('/devices/edit', body);
      if (success) {
        success();
      }
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async delete(deviceId: string, success?: () => void) {
    try {
      await axios.post('/devices/unlink', { deviceId });
      if (success) {
        success();
      }
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setAutoStartDuration(time: TAutostartTimeValues, success?: () => void) {
    try {
      await axios.post('/devices/params/autostart_time', {
        deviceId: this.deviceId,
        time,
      });
      if (success) {
        success();
      }
      this.dispatch({
        type: SET_AUTOSTART_PARAMS,
        params: { time },
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async toggleAutostartVoltage(success?: () => void) {
    try {
      const enabled = !this.getState().devicesInfo.info?.params?.autostart
        .voltageEnabled;
      this.dispatch({
        type: SET_AUTOSTART_PARAMS,
        params: { voltageEnabled: enabled },
      });
      await axios.post('/devices/params/autostart_voltage', {
        deviceId: this.deviceId,
        enabled,
      });
      if (success) {
        success();
      }
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setVoltageThreshold(
    threshold: TAutostartVoltageThresholdValues,
    success?: () => void
  ) {
    try {
      await axios.post('/devices/params/voltage_threshold', {
        deviceId: this.deviceId,
        threshold,
      });
      if (success) {
        success();
      }
      this.dispatch({
        type: SET_AUTOSTART_PARAMS,
        params: { voltageThreshold: threshold },
      });
    } catch (error) {
      handleDefaultErrors(error, () => {
        if (error.response.status === 408) {
          AlertController.show(error.response?.data.message, 'error');
        }
      });
    }
  }

  async setAutostartCondition(
    condition: TAutostartConditionValues,
    success?: () => void
  ) {
    try {
      this.dispatch({
        type: SET_AUTOSTART_PARAMS,
        params: { condition },
      });
      await axios.post('/devices/params/autostart_condition', {
        deviceId: this.deviceId,
        condition,
      });
      if (success) {
        success();
      }
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setAutostartInterval(
    interval: TAutostartIntervalValues,
    success?: () => void
  ) {
    try {
      await axios.post('/devices/params/autostart_interval', {
        deviceId: this.deviceId,
        interval,
      });
      if (success) {
        success();
      }
      this.dispatch({
        type: SET_AUTOSTART_PARAMS,
        params: { interval },
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setAutostartTemperature(
    temperature: TAutostartTemperatureValues,
    success?: () => void
  ) {
    try {
      await axios.post('/devices/params/autostart_temperature', {
        deviceId: this.deviceId,
        temperature,
      });
      if (success) {
        success();
      }
      this.dispatch({
        type: SET_AUTOSTART_PARAMS,
        params: { temperature },
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setHeatTime(time: THeatTime, success?: () => void) {
    try {
      await axios.post('/devices/params/heat_time', {
        deviceId: this.deviceId,
        time,
      });
      if (success) {
        success();
      }
      this.dispatch({
        type: UPDATE_PARAMS,
        value: { heatTime: time },
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setTimeZone(utc: number, success?: () => void) {
    try {
      await axios.post('/devices/params/utc_timezone', {
        deviceId: this.deviceId,
        utc,
      });
      if (success) {
        success();
      }
      this.dispatch({
        type: UPDATE_PARAMS,
        value: { utc },
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async getPhoneSettings() {
    try {
      const response = await axios.get('/devices/phone_settings', {
        params: { deviceId: this.deviceId },
      });
      this.dispatch({ type: SET_PHONE_SETTINGS, payload: response.data });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  setPhoneSettings(data: ISetPhoneSettings) {
    this.dispatch({ type: SET_PHONE_SETTINGS, payload: { data } });
  }

  async updatePhoneSettings(data: ISetPhoneSettings, success?: () => void) {
    try {
      await axios.post('/devices/phone_settings', {
        deviceId: this.deviceId,
        ...data,
      });
      if (success) {
        success();
      }
    } catch (error) {
      handleDefaultErrors(error);
    }
  }
}

export default new DeviceSettingsController();
