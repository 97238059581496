import initialState from '../initialState';
import { AnyAction } from 'redux';
import { SET_SCHEDULE_SETTINGS } from '../actionTypes';

export default function (state = initialState.schedule, action: AnyAction) {
  switch (action.type) {
    case SET_SCHEDULE_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
