import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import SettingsTitle from '../../components/SettingsTitle';
import Typography from '../../components/Typography';
import { useTranslation } from 'react-i18next';

type RefType = 'transfer_to_autoservice' | 'emergency_disarming';

type Props = RouteComponentProps<{ type: RefType }>;

const ReferenceTypePage = ({ match }: Props) => {
  const { type } = match.params;

  const { t } = useTranslation();

  const data = useMemo(
    () => ({
      transfer_to_autoservice: {
        title: t('reference.transferToAutoservice.title'),
        text: t('reference.transferToAutoservice.text'),
      },
      emergency_disarming: {
        title: t('reference.emergencyDisarming.title'),
        text: t('reference.emergencyDisarming.text'),
      },
    }),
    [t]
  );

  return (
    <div>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{data[type].title}</SettingsTitle>
      </Wrapper>
      <Wrapper withHorizontalPadding style={{ marginTop: 16 }}>
        <Typography
          size={14}
          color='third'
          style={{ whiteSpace: 'break-spaces' }}
        >
          {data[type].text}
        </Typography>
      </Wrapper>
    </div>
  );
};

export default ReferenceTypePage;
