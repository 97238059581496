import React, { useMemo } from 'react';
import Grid from '../../../../components/Grid';
import Svg from '../../../../components/Svg';
import Typography from '../../../../components/Typography';
import { IDeviceInfoItemProps, TDeviceInfoItemIcon } from './interface';

import simIcon from '../../../../img/deviceInfo/sim.svg';
import gasIcon from '../../../../img/deviceInfo/gas.svg';
import batteryIcon from '../../../../img/deviceInfo/battery.svg';
import speedIcon from '../../../../img/deviceInfo/speed.svg';
import wheelIcon from '../../../../img/deviceInfo/wheel.svg';
import engineIcon from '../../../../img/deviceInfo/engine.svg';

import alertIcon from '../../../../img/icons/alert.svg';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../../redux/types';

import './DeviceInfo.scss';
import { ESpeed } from '../../../../resources/types/app';
import { useTranslation } from 'react-i18next';

const icons: { [key in TDeviceInfoItemIcon]: string } = {
  sim: simIcon,
  gas: gasIcon,
  battery: batteryIcon,
  speed: speedIcon,
  wheel: wheelIcon,
  engine: engineIcon,
};

const DeviceInfoItem = ({
  icon,
  value,
  alert,
  postfix = '',
}: IDeviceInfoItemProps) => {
  if (value === null || value === undefined) return null;

  return (
    <Grid className='DeviceInfoItem' container direction='column' item>
      <Grid align='flex-start' container>
        <Svg src={icons[icon]} className='DeviceInfoItem-icon' />
        {alert && <Svg src={alertIcon} className='DeviceInfoItem-alertIcon' />}
      </Grid>
      <Typography
        className='DeviceInfoItem-value'
        color='second'
        size={12}
        dangerouslySetInnerHTML={{
          __html: `${value} ${postfix}`,
        }}
      />
    </Grid>
  );
};

const DeviceInfo = () => {
  const { t } = useTranslation();

  const deviceInfo = useSelector(
    ({ devicesInfo }: IGlobalState) => devicesInfo.info
  );

  const speedSource = useSelector((state: IGlobalState) => state.app.speed);

  const speed = useMemo(() => {
    if (speedSource === ESpeed.CAN) {
      return deviceInfo?.canState?.speed || 0;
    }

    return deviceInfo?.coordEvent?.speed || 0;
  }, [speedSource, deviceInfo]);

  return (
    <Grid className='DeviceInfo' container>
      {deviceInfo?.params?.app.balance && (
        <DeviceInfoItem
          icon='sim'
          value={`${deviceInfo?.params?.balance} ${t('common.currency')}`}
          alert={parseInt(deviceInfo?.params?.balance, 10) < 30}
        />
      )}
      <DeviceInfoItem
        icon='gas'
        value={deviceInfo?.canState?.fuel}
        alert={Boolean(
          deviceInfo?.canState?.fuel &&
            parseInt(deviceInfo.canState.fuel, 10) < 10
        )}
      />
      <DeviceInfoItem
        icon='battery'
        postfix={t('common.V')}
        value={deviceInfo?.state?.voltage}
      />
      <DeviceInfoItem icon='speed' postfix={t('common.km/h')} value={speed} />
      <DeviceInfoItem
        icon='wheel'
        postfix='°C'
        value={deviceInfo?.state?.internalTemp}
      />
      {deviceInfo?.params?.app.externalTemp && (
        <DeviceInfoItem
          icon='engine'
          postfix='°C'
          value={deviceInfo?.state?.externalTemp}
        />
      )}
    </Grid>
  );
};

export default DeviceInfo;
