import React from 'react';
import Button from '../Button';
import { Link } from 'react-router-dom';
import Grid from '../Grid';
import Svg from '../Svg';
import Typography from '../Typography';

import chevronLeftIcon from '../../img/icons/chevronLeft.svg';

import './SettingsBack.scss';
import { Props } from './interface';
import { useTranslation } from 'react-i18next';

const SettingsBack = (props: Props) => {
  const { to } = props;

  const { t } = useTranslation();

  return (
    <Button className='SettingsBack' component={Link} to={to} variant='text'>
      <Grid align='center' container>
        <Svg src={chevronLeftIcon} style={{ marginLeft: '-4px' }} />
        <Typography color='third' size={14} weight={400}>
          {t('common.back')}
        </Typography>
      </Grid>
    </Button>
  );
};

export default SettingsBack;
