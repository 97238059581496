import React from 'react';
import SettingsTitle from '../../components/SettingsTitle';
import Wrapper from '../../components/Wrapper';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import ListItem from '../../components/ListItem';
import Typography from '../../components/Typography';
import Switch from '../../components/Switch';
import { autostartConditionValues } from '../../resources/constVariables';
import AutostartToggle from './AutostartToggle';
import AutostartConditionRadio from './AutostartConditionRadio';
import TimeSelect from './TimeSelect';

import './AutostartSettingsPage.scss';
import usePending from '../../hooks/usePending';
import VoltageSelect from './VoltageSelect';
import { useTranslation } from 'react-i18next';
import SettingsLink from '../../components/SettingsLink';
import ScheduleController from '../../redux/controllers/ScheduleController';

const AutostartSettingsPage = () => {
  const autostartInfo = useSelector(
    ({ devicesInfo }: IGlobalState) => devicesInfo.info?.params?.autostart
  );
  const isScheduleEnabled = useSelector(
    ({ schedule }: IGlobalState) => schedule.enable
  );

  const { t } = useTranslation();

  const [waitToggleVoltage, onToggleVoltage] = usePending(() =>
    DeviceSettingsController.toggleAutostartVoltage()
  );

  const [waitToggleSchedule, onToggleSchedule] = usePending(() =>
    ScheduleController.toggleAutostartSchedule()
  );

  return (
    <div>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('settings.autostartSettings')}</SettingsTitle>
        <TimeSelect />
      </Wrapper>
      <div className='AutostartSettingsPage-settingsList'>
        <AutostartToggle />
        {autostartConditionValues.map(
          item =>
            item !== 'off' && (
              <ListItem key={item}>
                <AutostartConditionRadio item={item} />
              </ListItem>
            )
        )}
        <ListItem>
          <Typography size={16} color='main'>
            {t('heatWorkTime.autostartByVoltage')}
          </Typography>
          <Switch
            disabled={waitToggleVoltage}
            checked={autostartInfo?.voltageEnabled}
            onChange={onToggleVoltage}
          />
        </ListItem>
      </div>
      <Wrapper top withHorizontalPadding>
        <VoltageSelect />
      </Wrapper>
      <div className='AutostartSettingsPage-settingsList AutostartSettingsPage-autostartBySchedule'>
        <ListItem>
          <Typography size={16} color='main'>
            {t('heatWorkTime.autostartBySchedule')}
          </Typography>
          <Switch
            disabled={waitToggleSchedule}
            checked={isScheduleEnabled}
            onChange={onToggleSchedule}
          />
        </ListItem>
        {isScheduleEnabled && (
          <SettingsLink
            label={t('heatWorkTime.schedule')}
            to='/schedule'
            hint={t('heatWorkTime.scheduleHint')}
          />
        )}
      </div>
    </div>
  );
};

export default AutostartSettingsPage;
