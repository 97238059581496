import React from 'react';
import classNames from 'classnames';

import './Wrapper.scss';
import { Props } from './interface';

const Wrapper = ({
  className,
  children,
  withHorizontalPadding,
  top,
  bottom,
  ...props
}: Props) => {
  return (
    <div
      {...props}
      className={classNames(
        'Wrapper',
        withHorizontalPadding && 'Wrapper-horizontalPadding',
        top && 'Wrapper-top',
        bottom && 'Wrapper-bottom',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Wrapper;
