import { AnyAction } from 'redux';
import initialState from '../initialState';
import {
  CLEAR_STORE,
  GET_USER_INFO,
  GET_USER_SETTINGS,
  SET_LANGUAGE,
  SET_TOKEN,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
} from '../actionTypes';
import { setLocalStorage } from '../../resources/functions';
import {
  STORAGE_USER_INFO,
  STORAGE_USER_SETTINGS,
} from '../../resources/storageVariables';
import { IUser } from '../../resources/types/user';

export default function (state = initialState.user, action: AnyAction) {
  switch (action.type) {
    case SIGN_IN:
    case SIGN_UP:
      setLocalStorage(STORAGE_USER_INFO, action.value.data);

      return {
        ...state,
        auth: action.value.data,
      };

    case SIGN_OUT:
      localStorage.clear();

      return {
        auth: null,
        info: null,
        settings: null,
      };

    case GET_USER_INFO:
      return {
        ...state,
        info: action.payload.data,
      };

    case GET_USER_SETTINGS:
      setLocalStorage(STORAGE_USER_SETTINGS, action.payload.data);

      return {
        ...state,
        settings: action.payload.data,
      };

    case SET_LANGUAGE: {
      const settings = {
        ...state.settings,
        language: action.payload,
      };

      setLocalStorage(STORAGE_USER_SETTINGS, settings);

      return {
        ...state,
        settings,
      };
    }

    case CLEAR_STORE:
      return {
        ...state,
        auth: null,
      };

    case SET_TOKEN: {
      const auth = state.auth || ({} as IUser);

      auth.accessToken = action.payload;

      setLocalStorage(STORAGE_USER_INFO, auth);

      return {
        ...state,
        auth,
      };
    }

    default:
      return state;
  }
}
