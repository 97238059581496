import React, { useCallback, useState } from 'react';
import { Calendar } from 'react-date-range';
import Dialog, { DialogActions } from '../Dialog';
import { DatepickerOnChange, Props } from './interface';
import Button from '../Button';
import './Datepicker.scss';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../redux/controllers/AppController';
import useDateFnsLocale from '../../hooks/useDateFnsLocale';
import { useTranslation } from 'react-i18next';

const Datepicker = (props: Props) => {
  const { onClose, open, date, onChange } = props;

  const { t } = useTranslation();

  const theme = useSelector(selectTheme);

  const { locale } = useDateFnsLocale();

  const [value, setValue] = useState<Date | null>(date);

  const handleChange = useCallback<DatepickerOnChange>(
    nextDate => {
      setValue(nextDate);
    },
    [onChange]
  );

  const handleSubmit = useCallback(() => {
    onChange(value);
    onClose();
  }, [value, onClose, onChange]);

  return (
    <Dialog open={open} onClose={onClose} className='Datepicker'>
      <Calendar
        color={theme === 'dark' ? '#e65100' : '#EB353D'}
        locale={locale}
        date={value}
        onChange={handleChange}
      />
      <DialogActions>
        <Button className='Datepicker-close' onClick={onClose} variant='text'>
          {t('common.cancel')}
        </Button>
        <Button
          className='Datepicker-submit'
          onClick={handleSubmit}
          variant='text'
        >
          {t('common.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Datepicker;
