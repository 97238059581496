import {
  IPopoverAnchorOrigin,
  TPopoverOriginHorizontal,
  TPopoverOriginVertical,
} from './interface';

export const DEFAULT_ANCHOR_ORIGIN: IPopoverAnchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const calculatePopoverTop = (
  popoverEl: HTMLElement,
  anchorEl: HTMLElement,
  anchorOrigin?: TPopoverOriginVertical,
  transformOrigin?: TPopoverOriginVertical
) => {
  let { top } = anchorEl.getBoundingClientRect();
  const offset = anchorEl.offsetHeight;
  const popoverOffset = popoverEl.offsetHeight;

  if (anchorOrigin === 'center') {
    top += offset / 2;
  }

  if (anchorOrigin === 'bottom') {
    top += offset;
  }

  if (transformOrigin === 'center') {
    top -= popoverOffset / 2;
  }

  if (transformOrigin === 'bottom') {
    top -= popoverOffset;
  }

  const bottom = top + popoverEl.offsetHeight;
  if (bottom > window.innerHeight) {
    top = top - 20 - (bottom - window.innerHeight);
  }

  if (top < 0) {
    top = 20;
  }

  return top;
};

export const calculatePopoverLeft = (
  popoverEl: HTMLElement,
  anchorEl: HTMLElement,
  anchorOrigin?: TPopoverOriginHorizontal,
  transformOrigin?: TPopoverOriginHorizontal
) => {
  let { left } = anchorEl.getBoundingClientRect();
  const offset = anchorEl.offsetWidth;
  const popoverOffset = popoverEl.offsetWidth;

  if (anchorOrigin === 'center') {
    left += offset / 2;
  }

  if (anchorOrigin === 'right') {
    left += offset;
  }

  if (transformOrigin === 'center') {
    left -= popoverOffset / 2;
  }

  if (transformOrigin === 'right') {
    left -= popoverOffset;
  }

  return left;
};
