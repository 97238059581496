import React from 'react';
import Wrapper from '../../components/Wrapper';
import SettingsTitle from '../../components/SettingsTitle';
import Slider from '../../components/Slider';

import './SensitivitySettingsPage.scss';
import { IGlobalState } from '../../redux/types';
import { useSelector } from 'react-redux';
import { TSensitivity, TSensitivityValue } from '../../resources/types/devices';
import DevicesInfoController from '../../redux/controllers/DevicesInfoController';
import { useTranslation } from 'react-i18next';

const marks = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
};

const selectSensitivity = (state: IGlobalState) =>
  state.devicesInfo.info?.params?.sensitivity;

const SensitivitySettingsPage = () => {
  const sensitivity = useSelector(selectSensitivity);

  const { t } = useTranslation();

  const onChange = (name: TSensitivity) => (value: number) => {
    DevicesInfoController.updateSensitivity(name, value as TSensitivityValue);
  };

  return (
    <div className='SensitivitySettingsPage'>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('settings.sensorsSettings')}</SettingsTitle>
        <Slider
          label={t('sensorsSettings.hitSensorSensitivity')}
          min={0}
          max={9}
          value={sensitivity?.hit}
          onChange={onChange('hit')}
          marks={marks}
        />
        <Slider
          label={t('sensorsSettings.tiltSensorSensitivity')}
          min={0}
          max={9}
          value={sensitivity?.tilt}
          onChange={onChange('tilt')}
          marks={marks}
        />
        <Slider
          label={t('sensorsSettings.motionSensorSensitivity')}
          min={0}
          max={9}
          value={sensitivity?.motion}
          onChange={onChange('motion')}
          marks={marks}
        />
      </Wrapper>
    </div>
  );
};

export default SensitivitySettingsPage;
