import Controller from './Controller';
import axios from '../../lib/axios';
import { SET_SCHEDULE_SETTINGS } from '../actionTypes';
import { handleDefaultErrors } from '../../resources/functions';
import { TScheduleState } from '../../resources/types/schedule';

class ScheduleController extends Controller {
  async toggleAutostartSchedule() {
    try {
      const isEnabled = this.getState().schedule.enable;

      await axios.post(
        `/devices/schedule_settings/${isEnabled ? 'disable' : 'enable'}`,
        { deviceId: this.deviceId }
      );

      this.dispatch({
        type: SET_SCHEDULE_SETTINGS,
        payload: { enable: !isEnabled },
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async setScheduleSettings(settings: Partial<Omit<TScheduleState, 'enable'>>) {
    this.dispatch({
      type: SET_SCHEDULE_SETTINGS,
      payload: settings,
    });
  }

  async updateScheduleSettings() {
    try {
      const { enable, ...settings } = this.getState().schedule;

      await axios.post(`/devices/schedule_settings`, {
        deviceId: this.deviceId,
        ...settings,
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }
}

export default new ScheduleController();
