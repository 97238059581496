import React, { useEffect } from 'react';
import { Props } from './interface';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './Modal.scss';

const Modal = ({ open, onClose, fade, children }: Props) => {
  useEffect(() => {
    open
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');
  }, [open]);

  return ReactDOM.createPortal(
    <CSSTransition
      classNames='Modal-transition'
      in={open}
      timeout={200}
      unmountOnExit
    >
      <div className='Modal'>
        <div
          onClick={onClose}
          style={{
            position: 'fixed',
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            backgroundColor: fade ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
          }}
        />
        {children}
      </div>
    </CSSTransition>,
    document.body
  );
};

export default Modal;
