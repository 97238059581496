import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { positions, Provider as AlertProvider } from 'react-alert';

import AlertTemplate from './components/AlertTemplate';
import App from './App';

import { Provider } from 'react-redux';
import { store } from './redux/createStore';

import './scss/index.scss';

import './lib/i18next';
import { useTranslation } from 'react-i18next';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 8 * 1000,
  offset: '10px',
  containerStyle: {
    zIndex: 10000,
  },
};

const AppContainer = () => {
  const { ready } = useTranslation();

  if (!ready) return null;

  return <App />;
};

ReactDOM.render(
  <BrowserRouter>
    <AlertProvider {...options} template={AlertTemplate}>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
