import React, { useEffect, useMemo, useState } from 'react';
import Grid from '../../../../components/Grid';
import Svg from '../../../../components/Svg';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import DeviceControlController from '../../../../redux/controllers/DeviceControlController';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../../redux/types';
import classNames from 'classnames';

import engineButtonIcon from '../../../../img/carControl/engineButton.svg';

import './EngineButton.scss';
import useClickDelay from '../../../../hooks/useClickDelay';
import moment from 'moment';
import { Props } from './interface';
import { useTranslation } from 'react-i18next';

const EngineButton = (props: Props) => {
  const { isSmall } = props;

  const { loading, info } = useSelector(
    ({ devicesInfo, loadings }: IGlobalState) => ({
      loading: loadings.engine,
      info: devicesInfo.info,
    })
  );

  const { t } = useTranslation();

  const engineRunning = React.useMemo(() => info?.state?.engineRunning, [info]);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval: any;

    if (
      engineRunning &&
      info?.engineAutostart &&
      info?.params?.autostart?.time
    ) {
      const diffMs = moment().diff(moment(info.engineAutostart));
      setTimer(info.params.autostart.time * 60000 - diffMs);

      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer - 1000 > 0) {
            return prevTimer - 1000;
          } else {
            clearInterval(interval);
            interval = null;
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      setTimer(0);
      clearInterval(interval);
      interval = null;
    };
  }, [engineRunning, info?.engineAutostart, info?.params?.autostart?.time]);

  const time = React.useMemo(() => {
    const minutes = Math.floor(timer / 60000);
    const seconds = Math.floor((timer % 60000) / 1000);

    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }, [timer]);

  const clickDelay = useClickDelay();

  const engineStartedByKey = useMemo(() => {
    return info?.lastEngineEvent === 'engine_start_key';
  }, [info]);

  return (
    <Grid
      {...clickDelay(() => DeviceControlController.toggleEngine())}
      disabled={
        (info?.state?.ignition && !info?.state?.engineRunning) ||
        engineStartedByKey
      }
      align='center'
      className={classNames('EngineButton', isSmall && 'EngineButton-small')}
      component={Button}
      container
      direction='column'
      justify='center'
      loading={loading}
      variant='text'
    >
      <Svg className='EngineButton-circle' src={engineButtonIcon} />
      <div
        className={classNames(
          'EngineButton-engineStatusColor',
          engineRunning && 'EngineButton-engineStatusColor-active'
        )}
      />
      <Typography color='main' size={24} weight={700}>
        {engineRunning ? 'STOP' : 'START'}
      </Typography>
      <div className='EngineButton-divider' />
      <Typography className='EngineButton-status' color='second' size={12}>
        {engineRunning && !engineStartedByKey ? time : t('control.engineOff')}
      </Typography>
    </Grid>
  );
};

export default EngineButton;
