import React, { useCallback } from 'react';
import { Props } from './interface';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import leaflet from 'leaflet';
import './TrackingCluster.scss';

const TrackingCluster = (props: Props) => {
  const { children } = props;

  const createClusterCustomIcon = useCallback((cluster: any) => {
    return leaflet.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-custom',
      iconSize: leaflet.point(40, 40, true),
    });
  }, []);

  return (
    <MarkerClusterGroup
      iconCreateFunction={createClusterCustomIcon}
      disableClusteringAtZoom={17}
      spiderfyOnMaxZoom={false}
      showCoverageOnHover={false}
    >
      {children}
    </MarkerClusterGroup>
  );
};

export default TrackingCluster;
