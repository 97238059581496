import React, { useCallback, useEffect, useState } from 'react';
import { Props } from './interface';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/Dialog';
import FormElement from '../../components/FormElement';
import Typography from '../../components/Typography';
import Button from '../../components/Button';

import './DevicesDialog.scss';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import AlertController from '../../redux/controllers/AlertController';
import DevicesInfoController from '../../redux/controllers/DevicesInfoController';
import { clearPhoneMask } from '../../resources/functions';
import InputMask from '../../components/InputMask';
import { useTranslation } from 'react-i18next';

const DevicesDialog = ({ device, ...dialogProps }: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [bodyType, setBodyType] = useState('');

  useEffect(() => {
    if (device) {
      setName(device.name);
      setPhone(device.phone);
      setBodyType(device.bodyType);
    } else {
      setName('');
      setPhone('');
      setBodyType('');
    }
  }, [device]);

  const onSave = useCallback(() => {
    if (device) {
      DeviceSettingsController.edit(
        {
          deviceId: String(device.id),
          name,
          phone: clearPhoneMask(phone),
          bodyType,
        },
        () => {
          dialogProps.onClose();
          AlertController.show(t('myCars.infoSaved'), 'success');
          DevicesInfoController.getDevices();
        }
      );
    }
  }, [device, name, phone, bodyType, t]);

  const onDelete = React.useCallback(() => {
    if (device) {
      DeviceSettingsController.delete(String(device.id), () => {
        dialogProps.onClose();
        AlertController.show(t('myCars.deleteNote'), 'success');
        DevicesInfoController.getDevices();
      });
    }
  }, [device, t]);

  return (
    <Dialog fullWidth {...dialogProps} className='DevicesDialog'>
      <DialogTitle>
        <Typography color='main' size={24} weight={700}>
          {device?.name}
        </Typography>
      </DialogTitle>
      <DialogContent className='DevicesDialog-content'>
        <FormElement
          fullWidth
          label={t('common.name')}
          placeholder={`${t('common.enter')} ${t('common.name')}`}
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <InputMask
          value={phone}
          onChange={(event: any) => setPhone(event.target.value)}
          placeholder={`${t('common.enter')} ${t('myCars.SIMNumber')}`}
          fullWidth
          label={t('myCars.SIMNumber')}
        />
      </DialogContent>
      <DialogActions className='DevicesDialog-actions'>
        <Button fullWidth size='middle' onClick={onSave}>
          {t('common.save')}
        </Button>
        <Button fullWidth size='middle' onClick={onDelete}>
          {t('myCars.deleteCar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DevicesDialog;
