import React, { useCallback, useRef, useState } from 'react';
import { Props, TSelectIcon } from './interface';
import Popover from '../Popover';
import classNames from 'classnames';
import Svg from '../Svg';

import chevronIcon from '../../img/icons/chevronDown.svg';
import miniChevronIcon from '../../img/icons/miniChevronDown.svg';

import './Select.scss';
import Typography from '../Typography';

const icons: { [key in TSelectIcon]: string } = {
  default: chevronIcon,
  mini: miniChevronIcon,
};

const Select = ({
  value,
  onChange,
  options,
  placeholder,
  variant = 'outlined',
  fullWidth,
  popoverProps,
  icon = 'default',
  withoutChevron,
}: Props) => {
  const [open, setOpen] = useState(false);

  const onToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const anchorRef = useRef<HTMLDivElement>(null);

  const onSelect = useCallback(
    (nextValue: string) => () => {
      onToggle();
      onChange(nextValue);
    },
    [onToggle, onChange]
  );

  const cn = classNames('Select', {
    [`Select-${variant}`]: variant,
    'Select-fullWidth': fullWidth,
    'Select-focus': open,
  });

  const listCn = classNames('SelectList', {
    [`SelectList-${variant}`]: variant,
  });

  return (
    <div ref={anchorRef} className={cn} tabIndex={1}>
      <div className='Select-label' onClick={onToggle}>
        <Typography color='main' size={18} weight={500}>
          {options.find(item => String(item.value) === String(value))?.label ||
            placeholder}
        </Typography>
        {!withoutChevron && <Svg src={icons[icon]} className='Select-icon' />}
      </div>
      <Popover
        {...popoverProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorRef={anchorRef}
        onClose={onToggle}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={listCn}>
          {options.map(item => (
            <Typography
              key={item.value}
              className='Select-item'
              color='main'
              onClick={onSelect(item.value)}
              size={14}
              weight={500}
            >
              {item.label}
            </Typography>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default Select;
