import React from 'react';
import classNames from 'classnames';
import { Props } from './interface';

import './Switch.scss';

const Switch = ({ checked, onChange, disabled, ...props }: Props) => {
  return (
    <div
      className={classNames(
        'Switch',
        checked && 'Switch-checked',
        disabled && 'Switch-disabled',
        props.className
      )}
    >
      <label>
        <input
          {...props}
          disabled={disabled}
          onChange={onChange}
          type='checkbox'
          className='Switch-input'
          checked={checked}
        />
        <div className='Switch-indicator' />
      </label>
    </div>
  );
};

export default Switch;
