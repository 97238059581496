import initialState from '../initialState';
import { AnyAction } from 'redux';
import {
  GET_TRACKING_HISTORY,
  GET_TRACKING_STATS,
  SET_CURRENT_COORDS,
} from '../actionTypes';

export default function (state = initialState.tracking, action: AnyAction) {
  switch (action.type) {
    case SET_CURRENT_COORDS:
      return {
        ...state,
        current: action.payload.data,
      };

    case GET_TRACKING_HISTORY:
      return {
        ...state,
        history: action.payload.data,
      };

    case GET_TRACKING_STATS:
      return {
        ...state,
        stats: action.payload.data,
      };

    default:
      return state;
  }
}
