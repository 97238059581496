import { useState } from 'react';

const usePending = (
  fn: (...args: any) => Promise<any>
): [boolean, (...args: any) => Promise<any>] => {
  const [pending, setPending] = useState(false);

  const onFetch = async (...args: any) => {
    setPending(true);
    await fn(...args);
    setPending(false);
  };

  return [pending, onFetch];
};

export default usePending;
