import React, { useMemo } from 'react';
import { Marker } from 'react-leaflet';
import { Props } from './interface';
import leaflet, { DivIcon, Icon } from 'leaflet';
import marker from '../../img/map/marker.svg';
import markerDarkTheme from '../../img/map/markerDarkTheme.svg';
import TrackingPopup from '../TrackingPopup';
import moment from 'moment';
import './TrackingMarker.scss';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../redux/controllers/AppController';

const TrackingMarker = (props: Props) => {
  const { position, children, popupData, type, iconOptions } = props;

  const theme = useSelector(selectTheme);

  const icon = useMemo<Icon | DivIcon>(() => {
    if (type) {
      return leaflet.divIcon({
        className: `specific-marker specific-marker-${type}`,
        html: `<div class="specific-marker-ball">${type}</div><div class="specific-marker-time">${moment(
          popupData?.date
        ).format('HH:mm')}</div>`,
        iconSize: [56, 56],
        iconAnchor: [28, 56],
        popupAnchor: [2, -30],
      });
    }

    return leaflet.icon({
      iconUrl: theme === 'light' ? marker : markerDarkTheme,
      iconSize: [28, 31],
      iconAnchor: [14, 31],
      popupAnchor: [2, -28],
      ...iconOptions,
    });
  }, [type, popupData, iconOptions, theme]);

  return (
    <Marker position={position} icon={icon}>
      {popupData && <TrackingPopup {...popupData} />}
      {children}
    </Marker>
  );
};

export default TrackingMarker;
