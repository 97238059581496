import React from 'react';
import { Props } from './interface';
import Modal from '../Modal';
import classNames from 'classnames';
import Paper from '../Paper';

import './Dialog.scss';

const Dialog = ({
  scroll,
  fullWidth,
  maxWidth = 'small',
  children,
  className,
  ...modalProps
}: Props) => {
  return (
    <Modal {...modalProps} fade>
      <div
        className={classNames(
          'Dialog-container',
          scroll === 'body' ? 'Dialog-scrollBody' : 'Dialog-scrollPaper',
          className
        )}
      >
        <Paper
          style={{ maxWidth: typeof maxWidth === 'number' ? maxWidth : '' }}
          className={classNames('Dialog-paper', {
            'Dialog-paper-fullWidth': fullWidth,
            [`Dialog-paper-maxWidth-${maxWidth}`]: typeof maxWidth === 'string',
          })}
        >
          {children}
        </Paper>
      </div>
    </Modal>
  );
};

export default Dialog;
