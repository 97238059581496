import React, { useCallback } from 'react';
import SettingsLink from '../../components/SettingsLink';
import './AppSettingsPage.scss';
import Radio from '../../components/Radio';
import AppController, {
  selectTheme,
} from '../../redux/controllers/AppController';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import { ESpeed } from '../../resources/types/app';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../components/LanguageSelect';
import Switch from '../../components/Switch';

const AppSettingsPage = () => {
  const speed = useSelector((state: IGlobalState) => state.app.speed);
  const theme = useSelector(selectTheme);

  const { t } = useTranslation();

  const handleRadio = useCallback(
    (nextSpeed: ESpeed) => () => {
      AppController.setSpeed(nextSpeed);
    },
    []
  );

  const changeTheme = useCallback(() => {
    AppController.setTheme(theme === 'light' ? 'dark' : 'light');
  }, [theme]);

  return (
    <div className='AppSettingsPage'>
      <SettingsLink
        icon='buttons'
        label={t('settings.buttonsSettings')}
        to='/buttons'
      />
      <SettingsLink
        icon='speed'
        label={t('settings.speedSource')}
        isDiv
        renderAction={() => null}
      />
      <div className='AppSettingsPage-radioGroup'>
        <Radio
          label={ESpeed.CAN}
          name='speed'
          checked={speed === ESpeed.CAN}
          onChange={handleRadio(ESpeed.CAN)}
        />
        <Radio
          label={ESpeed.GPS}
          name='speed'
          checked={speed === ESpeed.GPS}
          onChange={handleRadio(ESpeed.GPS)}
        />
      </div>
      <LanguageSelect />
      <SettingsLink
        icon='moon'
        label={t('settings.darkMode')}
        isDiv
        renderAction={props => (
          <Switch
            {...props}
            checked={theme === 'dark'}
            onChange={changeTheme}
          />
        )}
      />
    </div>
  );
};

export default AppSettingsPage;
