import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SettingsTitle from '../../components/SettingsTitle';
import Wrapper from '../../components/Wrapper';
import FormElement from '../../components/FormElement';
import './SettingsPhonesPage.scss';
import InputMask from '../../components/InputMask';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import Button from '../../components/Button';
import { clearPhoneMask } from '../../resources/functions';
import { ISetPhoneSettings } from '../../resources/types/devices';
import AlertController from '../../redux/controllers/AlertController';
import SelectDialog from '../../components/SelectDialog';
import { useTranslation } from 'react-i18next';

const SettingsPhonesPage = () => {
  const { phonesState, deviceId } = useSelector((state: IGlobalState) => ({
    phonesState: state.devicesInfo.phones,
    deviceId: state.devicesInfo.curDeviceId,
  }));

  const { t } = useTranslation();

  const balanceIntervalItems = useMemo(() => {
    const h = (hours: number) => `${hours} ${t('common.h')}.`;

    return [
      { label: t('common.disable'), value: '' },
      { label: h(1), value: 1 },
      { label: h(2), value: 2 },
      { label: h(3), value: 3 },
      { label: h(6), value: 6 },
      { label: h(12), value: 12 },
      { label: h(24), value: 24 },
      { label: h(48), value: 48 },
      { label: h(72), value: 72 },
      { label: h(96), value: 96 },
    ];
  }, [t]);

  const [state, setState] = useState({
    firstNumber: '',
    secondNumber: '',
    sosNumber: '',
    balanceCommand: '',
    balanceInterval: 0,
  });

  useEffect(() => {
    if (deviceId) {
      DeviceSettingsController.getPhoneSettings();
    }
  }, [deviceId]);

  useEffect(() => {
    setState({
      firstNumber: phonesState.firstNumber,
      secondNumber: phonesState.secondNumber,
      balanceCommand: phonesState.balanceCommand,
      sosNumber: phonesState.sosNumber,
      balanceInterval: phonesState.balanceInterval,
    });
  }, [phonesState]);

  const handleChange = useCallback(
    (property: keyof typeof state) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setState(prevState => ({
          ...prevState,
          [property]: event.target.value,
        }));
      },
    []
  );

  const handleChangeBalanceInterval = useCallback((value: any) => {
    setState(prevState => ({
      ...prevState,
      balanceInterval: value,
    }));
  }, []);

  const checkPhoneNumber = useCallback(
    (phoneNumber: string) => {
      const number = phoneNumber.slice(1);
      if (number.length > 1 && number.length < 10) {
        AlertController.show(t('phoneNumbers.phoneCondition'), 'error');
        return false;
      }
      return true;
    },
    [t]
  );

  const handleSubmit = useCallback(() => {
    const firstNumber = clearPhoneMask(state.firstNumber);
    const secondNumber = clearPhoneMask(state.secondNumber);
    const sosNumber = clearPhoneMask(state.sosNumber);

    const data: ISetPhoneSettings = {};

    let isError = false;

    if (firstNumber !== phonesState.firstNumber) {
      data.firstNumber = firstNumber;
      isError = !checkPhoneNumber(firstNumber);
    }

    if (secondNumber !== phonesState.secondNumber) {
      data.secondNumber = secondNumber;
      isError = !checkPhoneNumber(secondNumber);
    }

    if (sosNumber !== phonesState.sosNumber) {
      data.sosNumber = sosNumber;
      isError = !checkPhoneNumber(sosNumber);
    }

    if (state.balanceCommand !== phonesState.balanceCommand) {
      data.balanceCommand = state.balanceCommand;
    }

    if (state.balanceInterval !== phonesState.balanceInterval) {
      data.balanceInterval = state.balanceInterval;
    }

    if (!isError) {
      DeviceSettingsController.updatePhoneSettings(data, () => {
        AlertController.show(t('phoneNumbers.phonesSaved'), 'success');
        DeviceSettingsController.setPhoneSettings(data);
      });
    }
  }, [state, phonesState, checkPhoneNumber, t]);

  return (
    <div className='SettingsPhonesPage'>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('settings.phoneNumbers')}</SettingsTitle>
        <InputMask
          value={state.firstNumber}
          onChange={handleChange('firstNumber')}
          fullWidth
          label={t('phoneNumbers.firstPhone')}
        />
        <InputMask
          value={state.secondNumber}
          onChange={handleChange('secondNumber')}
          fullWidth
          label={t('phoneNumbers.secondPhone')}
        />
        <InputMask
          value={state.sosNumber}
          onChange={handleChange('sosNumber')}
          fullWidth
          label={t('phoneNumbers.SOSPhone')}
        />
        <FormElement
          inputProps={{
            maxLength: 12,
          }}
          value={state.balanceCommand}
          onChange={handleChange('balanceCommand')}
          fullWidth
          label={t('phoneNumbers.balanceCode')}
        />
        <FormElement label={t('phoneNumbers.balanceInterval')}>
          <SelectDialog
            label={
              state.balanceInterval
                ? `${state.balanceInterval} ${t('common.h')}.`
                : t('common.disabled')
            }
            items={balanceIntervalItems}
            onChange={handleChangeBalanceInterval}
          />
        </FormElement>
        <Button size='middle' fullWidth onClick={handleSubmit}>
          {t('common.save')}
        </Button>
      </Wrapper>
    </div>
  );
};

export default SettingsPhonesPage;
