import { useSelector } from 'react-redux';
import { IGlobalState } from '../redux/types';
import { useEffect, useState } from 'react';
import { EMomentLocales } from '../resources/types/app';
import { Locale } from 'date-fns';

const useDateFnsLocale = () => {
  const language = useSelector(
    (state: IGlobalState) => state.user.settings?.language
  );

  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    if (language) {
      const locale = EMomentLocales[language];
      import(`date-fns/locale/${locale}`).then(value => {
        setLocale(value.default);
      });
    }
  }, [language]);

  return { locale };
};

export default useDateFnsLocale;
