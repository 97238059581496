import React, { useMemo, useState } from 'react';
import { IGlobalState } from '../../redux/types';
import { useSelector } from 'react-redux';

import './HeatTimeSettings.scss';
import { heatTime } from '../../resources/constVariables';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import usePending from '../../hooks/usePending';
import { THeatTime } from '../../resources/types/devices';
import { useTranslation } from 'react-i18next';
import { DialogListItem } from '../../components/DialogList/interface';
import DialogList from '../../components/DialogList';
import SettingsLink from '../../components/SettingsLink';

const selectHeatTime = (state: IGlobalState) =>
  state.devicesInfo.info?.params?.heatTime;

const HeatTimeSettings = () => {
  const value = useSelector(selectHeatTime);

  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const toggle = () => setDialogOpen(!dialogOpen);

  const [pending, onFetch] = usePending((time: THeatTime) =>
    DeviceSettingsController.setHeatTime(time)
  );

  const onSelect = (value: string) => {
    toggle();
    onFetch(Number(value));
  };

  const items = useMemo<DialogListItem[]>(() => {
    return heatTime.map(item => ({
      value: String(item),
      label: `${item} ${t('common.min')}.`,
    }));
  }, []);

  return (
    <>
      <SettingsLink
        disabled={pending}
        isDiv
        icon='flow'
        onClick={toggle}
        label={t('settings.heatWorkTime')}
        hint={`${value || 0} ${t('common.min')}.`}
      />
      <DialogList
        open={dialogOpen}
        onClose={toggle}
        items={items}
        onChange={onSelect}
      />
    </>
  );
};

export default HeatTimeSettings;
