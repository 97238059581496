import Controller from './Controller';
import {
  DISABLE_SECURE_LOADING,
  DISABLE_ENGINE_BLOCK_LOADING,
  DISABLE_FIND_CAR_LOADING,
  DISABLE_HEAT_LOADING,
  DISABLE_TRUNK_LOADING,
  ENABLE_SECURE_LOADING,
  ENABLE_ENGINE_BLOCK_LOADING,
  ENABLE_FIND_CAR_LOADING,
  ENABLE_HEAT_LOADING,
  ENABLE_TRUNK_LOADING,
  UPDATE_STATE,
  ENABLE_ENGINE_LOADING,
  DISABLE_ENGINE_LOADING,
  ENABLE_DASHCAM_LOADING,
  DISABLE_DASHCAM_LOADING,
  ENABLE_SERVICE_LOADING,
  DISABLE_SERVICE_LOADING,
} from '../actionTypes';

class LoadingsController extends Controller {
  setEngine(enable?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_ENGINE_LOADING });
    } else {
      this.dispatch({ type: DISABLE_ENGINE_LOADING });
    }
  }

  setEngineBlock(enable?: boolean, error?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_ENGINE_BLOCK_LOADING });
    } else if (!error) {
      this.dispatch({ type: DISABLE_ENGINE_BLOCK_LOADING });
      this.dispatch({
        type: UPDATE_STATE,
        value: { blocked: !this.getState().devicesInfo.info?.state?.blocked },
      });
    } else {
      this.dispatch({ type: DISABLE_ENGINE_BLOCK_LOADING });
    }
  }

  setTrunk(enable?: boolean, error?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_TRUNK_LOADING });
    } else if (!error) {
      this.dispatch({ type: DISABLE_TRUNK_LOADING });
    } else {
      this.dispatch({ type: DISABLE_TRUNK_LOADING });
    }
  }

  setHeat(enable?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_HEAT_LOADING });
    } else {
      this.dispatch({ type: DISABLE_HEAT_LOADING });
    }
  }

  setFindCar(enable?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_FIND_CAR_LOADING });
    } else {
      this.dispatch({ type: DISABLE_FIND_CAR_LOADING });
    }
  }

  setSecure(enable?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_SECURE_LOADING });
    } else {
      this.dispatch({ type: DISABLE_SECURE_LOADING });
    }
  }

  setDashcam(enable?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_DASHCAM_LOADING });
    } else {
      this.dispatch({ type: DISABLE_DASHCAM_LOADING });
    }
  }

  setService(enable?: boolean) {
    if (enable) {
      this.dispatch({ type: ENABLE_SERVICE_LOADING });
    } else {
      this.dispatch({ type: DISABLE_SERVICE_LOADING });
    }
  }
}

export default new LoadingsController();
