import axios from '../../lib/axios';
import Controller from './Controller';
import {
  GET_USER_INFO,
  GET_USER_SETTINGS,
  SET_LANGUAGE,
  SET_TOKEN,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
} from '../actionTypes';
import {
  IChangePasswordData,
  ISigninData,
  ISignupData,
} from '../../resources/types/user';
import AlertController from './AlertController';
import { handleDefaultErrors } from '../../resources/functions';
import i18next from '../../lib/i18next';

class UserController extends Controller {
  async signIn(data: ISigninData, success: () => void) {
    try {
      const response = await axios.post('/users/login', data);

      this.dispatch({
        type: SIGN_IN,
        value: response.data,
      });

      success();
    } catch ({ response }) {
      if (response.data) {
        switch (response.status) {
          case 401:
            AlertController.show(
              i18next.t('common.wrongLoginOrPassword'),
              'error'
            );
            break;

          default:
            AlertController.show(response.data.message, 'error');
        }
      } else {
        AlertController.show(i18next.t('common.serverError'), 'error');
      }
    }
  }

  async signUp(data: ISignupData, success: () => void) {
    try {
      const response = await axios.post('/users/register', data);

      this.dispatch({
        type: SIGN_UP,
        value: response.data,
      });

      AlertController.show(i18next.t('common.registrationSuccess'), 'success');

      success();
    } catch ({ response }) {
      if (!response) {
        AlertController.show(response.data.message, 'error');
      }
    }
  }

  signOut() {
    this.dispatch({
      type: SIGN_OUT,
    });
  }

  async getInfo() {
    try {
      const response = await axios.get('/users/info');
      this.dispatch({
        type: GET_USER_INFO,
        payload: response.data,
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async getSettings() {
    try {
      const response = await axios.get('/users/settings');
      this.dispatch({
        type: GET_USER_SETTINGS,
        payload: response.data,
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async changePassword(body: IChangePasswordData, success: () => void) {
    try {
      await axios.post('/users/change_password', body);
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async sendResetPasswordCode(
    email: string,
    success: (resetId: string) => void
  ) {
    try {
      const response = await axios.post('/users/send_reset_password_code', {
        email,
      });
      success(response.data.data.resetId);
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async checkResetPasswordCode(
    resetId: string,
    code: string,
    success: () => void
  ) {
    try {
      await axios.post('/users/check_reset_password_code', { resetId, code });
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  async resetPassword(
    newPassword: string,
    resetId: string,
    code: string,
    success: () => void
  ) {
    try {
      await axios.post('/users/reset_password', { resetId, code, newPassword });
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  }

  setToken(token: string) {
    this.dispatch({ type: SET_TOKEN, payload: token });
  }

  async changeLanguage(language: string, success?: (token: string) => void) {
    try {
      const response = await axios.post('/users/settings/language', {
        language,
      });
      if (success) success(response.data.data.token);
      this.dispatch({ type: SET_LANGUAGE, payload: language });
    } catch (e) {
      handleDefaultErrors(e);
    }
  }
}

export default new UserController();
