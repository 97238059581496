import axios from 'axios';
import { getLocalStorage } from '../../resources/functions';
import { IUser } from '../../resources/types/user';
import { STORAGE_USER_INFO } from '../../resources/storageVariables';

export const SOCKET_URL = 'wss://app.startfone.ru:8081';

const Axios = axios.create({
  baseURL: '/api',
  responseType: 'json',
});

const accessToken = getLocalStorage<IUser>(STORAGE_USER_INFO)?.accessToken;

if (accessToken) {
  Axios.defaults.headers.common['x-access-token'] = accessToken;
}

export default Axios;
