// app

export const CLEAR_STORE = 'CLEAR_STORE';

export const INIT_SOCKET = 'INIT_SOCKET';
export const CLOSE_SOCKET = 'CLOSE_SOCKET';
export const SET_BUTTONS = 'SET_BUTTONS';
export const SET_SPEED = 'SET_SPEED';
export const SET_THEME = 'SET_THEME';
export const SET_TOKEN = 'SET_TOKEN';
export const INIT_APP = 'INIT_APP';

// users

export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const SET_LANGUAGE = 'SET_LANGUAGE';

// alert

export const SHOW_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

// devices info

export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';
export const GET_DEVICE_EVENTS = 'GET_DEVICE_EVENTS';
export const GET_DEVICE_SETTINGS = 'GET_DEVICE_SETTINGS';
export const SET_CUR_DEVICE = 'SET_CUR_DEVICE';
export const SET_TRUNK = 'SET_TRUNK';
export const UPDATE_DEVICE_INFO = 'UPDATE_DEVICE_INFO';
export const UPDATE_SENSITIVITY = 'UPDATE_SENSITIVITY';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const SET_BOTTOM_ALERT_MESSAGE = 'SET_BOTTOM_ALERT_MESSAGE';

// socket messages

export const UPDATE_CAN_STATE = 'UPDATE_CAN_STATE';
export const UPDATE_PARAMS = 'UPDATE_PARAMS';
export const UPDATE_STATE = 'UPDATE_STATE';

// loadings

export const ENABLE_ENGINE_LOADING = 'ENABLE_ENGINE_LOADING';
export const DISABLE_ENGINE_LOADING = 'DISABLE_ENGINE_LOADING';

export const ENABLE_ENGINE_BLOCK_LOADING = 'ENABLE_ENGINE_BLOCK_LOADING';
export const DISABLE_ENGINE_BLOCK_LOADING = 'DISABLE_ENGINE_BLOCK_LOADING';

export const ENABLE_TRUNK_LOADING = 'ENABLE_TRUNK_LOADING';
export const DISABLE_TRUNK_LOADING = 'DISABLE_TRUNK_LOADING';

export const ENABLE_HEAT_LOADING = 'ENABLE_HEAT_LOADING';
export const DISABLE_HEAT_LOADING = 'DISABLE_HEAT_LOADING';

export const ENABLE_FIND_CAR_LOADING = 'ENABLE_FIND_CAR_LOADING';
export const DISABLE_FIND_CAR_LOADING = 'DISABLE_FIND_CAR_LOADING';

export const ENABLE_SECURE_LOADING = 'ENABLE_SECURE_LOADING';
export const DISABLE_SECURE_LOADING = 'DISABLE_SECURE_LOADING';

export const ENABLE_DASHCAM_LOADING = 'ENABLE_DASHCAM_LOADING';
export const DISABLE_DASHCAM_LOADING = 'DISABLE_DASHCAM_LOADING';

export const ENABLE_SERVICE_LOADING = 'ENABLE_SERVICE_LOADING';
export const DISABLE_SERVICE_LOADING = 'DISABLE_SERVICE_LOADING';

// device settings

export const SET_AUTOSTART_PARAMS = 'SET_AUTOSTART_PARAMS';
export const SET_PHONE_SETTINGS = 'SET_PHONE_SETTINGS';

// device socket events

export const UPDATE_SOCKET_EVENTS = 'UPDATE_SOCKET_EVENTS';

// tracking

export const SET_CURRENT_COORDS = 'SET_CURRENT_COORDS';
export const GET_TRACKING_HISTORY = 'GET_TRACKING_HISTORY';
export const GET_TRACKING_STATS = 'GET_TRACKING_STATS';

// schedule

export const SET_SCHEDULE_SETTINGS = 'SET_SCHEDULE_SETTINGS';
