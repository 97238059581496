import AlertController from '../redux/controllers/AlertController';
import moment, { Moment } from 'moment';
import i18next from '../lib/i18next';
import UserController from '../redux/controllers/UserController';

export function ucFirst(str: string) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
}

export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage<T>(key: string): T | null {
  const value = localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
}

export function checkSameDate(date: Date, sameDate: Date) {
  return moment(moment(date).format('YYYY-MM-DD')).isSame(
    moment(sameDate).format('YYYY-MM-DD')
  );
}

export function handleDefaultErrors(
  error: any,
  handleCustomErrors?: (response: any) => void
) {
  const { response } = error;
  if (response) {
    switch (response.status) {
      case 401:
        AlertController.show(i18next.t('common.logInAgain'), 'error');
        UserController.signOut();
        return;

      case 404:
        AlertController.show(i18next.t('common.deviceNotFound'), 'error');
        return;

      case 500:
        AlertController.show(i18next.t('common.serverError'), 'error');
        return;
    }

    if (response.status > 500) {
      AlertController.show(i18next.t('common.error'), 'error');
      return;
    }

    if (handleCustomErrors) {
      handleCustomErrors(error);
    } else if (response.status !== 200) {
      AlertController.show(
        response.data.message || i18next.t('common.serverError'),
        'error'
      );
      return;
    }

    return true;
  }

  AlertController.show(i18next.t('common.error'), 'error');
  console.log(error);
}

export function debounce(
  func: (...args: any) => void,
  wait: number,
  immediate?: boolean
) {
  let timeout: any;

  return function executedFunction(this: any, ...args: any) {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
}

export function formatPhoneNumber(phoneNumberString: string) {
  if (typeof phoneNumberString !== 'string') return phoneNumberString;
  return phoneNumberString.replace(
    /\+7(\d{3})(\d{3})(\d{2})(\d{2})/,
    '+7 ($1) $2-$3-$4'
  );
}

export function clearPhoneMask(phoneMask: string) {
  return phoneMask.replace(/[\s()]/g, '');
}

export function secondsToHms(d: number) {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return { h, m, s };
}

export function getNavigatorLanguage() {
  return navigator.language.slice(0, 2);
}

export function getUtcStringValue(utc: number): string {
  let value: string | number = utc;

  if (value > -10 && value < 0) {
    value = `-0${Math.abs(value)}`;
  }

  if (value > 0) {
    if (value < 10) {
      value = `+0${value}`;
    } else {
      value = `+${value}`;
    }
  }

  return String(value);
}

export function setDayStart(date?: Moment | Date | string): Moment {
  return moment(date).set({
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}

export function setDayEnd(date?: Moment | Date | string): Moment {
  return moment(date).set({
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  });
}
