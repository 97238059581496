import React from 'react';
import classNames from 'classnames';
import { Props } from './interface';

import './Typography.scss';

function Typography<T>({
  component,
  color,
  weight,
  textDecoration,
  size,
  className,
  children,
  align,
  ...props
}: Props<T>) {
  const Component = component || 'span';

  const cn = classNames('Typography', className, {
    [`Typography-textDecoration-${textDecoration}`]: textDecoration,
    [`Typography-weight-${weight}`]: weight,
    [`Typography-align-${align}`]: align,
    [`Typography-color-${color}`]: color,
    [`Typography-size-${size}`]: size,
  });

  // @ts-ignore
  const injectedProps: T = props;

  return (
    <Component {...injectedProps} className={cn}>
      {children}
    </Component>
  );
}

export default Typography;
