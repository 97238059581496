import React, { useState } from 'react';
import SettingsLink from '../SettingsLink';
import { useTranslation } from 'react-i18next';
import DialogList from '../DialogList';
import usePending from '../../hooks/usePending';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { timeZonesOptions } from '../../resources/constVariables';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import { getUtcStringValue } from '../../resources/functions';

const TimeZoneSettings = () => {
  const { t } = useTranslation();

  const utc = useSelector(({ devicesInfo }: IGlobalState) => {
    const value = devicesInfo.info?.params?.utc;

    if (value === undefined) {
      return '';
    }

    return getUtcStringValue(value);
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const toggle = () => setDialogOpen(!dialogOpen);

  const [pending, onFetch] = usePending((timeZone: number) =>
    DeviceSettingsController.setTimeZone(timeZone)
  );

  const onSelect = (value: string) => {
    toggle();
    onFetch(value);
  };

  return (
    <>
      <SettingsLink
        onClick={toggle}
        disabled={pending}
        label={t('settings.timeZone')}
        icon='clock'
        isDiv
        hint={utc ? `UTC ${utc}` : undefined}
      />
      <DialogList
        open={dialogOpen}
        onClose={toggle}
        items={timeZonesOptions}
        onChange={onSelect}
      />
    </>
  );
};

export default TimeZoneSettings;
