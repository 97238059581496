import React from 'react';
import { Props } from './inteface';
import classNames from 'classnames';
import Typography from '../Typography';
import './EventAlert.scss';
import { useTranslation } from 'react-i18next';

const EventAlert = (props: Props) => {
  const { message } = props;
  const { t } = useTranslation();
  return (
    <div className={classNames('EventAlert', message && 'EventAlert-visible')}>
      <Typography size={14}>
        {message && t(`events.${message}` as any)}
      </Typography>
    </div>
  );
};

export default EventAlert;
