import React from 'react';
import { Props } from './interface';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/Dialog';
import Input from '../../components/Input/Input';
import Button from '../../components/Button';
import './EngineLockConfirmPasswordDialog.scss';
import useFormInput from '../../hooks/useFormInput';
import DeviceControlController from '../../redux/controllers/DeviceControlController';
import Typography from '../../components/Typography';
import { useTranslation } from 'react-i18next';

const EngineLockConfirmPasswordDialog = (props: Props) => {
  const { open, onClose } = props;

  const { t } = useTranslation();

  const password = useFormInput('');

  const handleSubmit = React.useCallback(() => {
    DeviceControlController.toggleBlock(password.value, () => {
      onClose();
    });
  }, [password.value, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography weight={700} size={18}>
          {t('common.enterAccountPassword')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Input
          {...password}
          type='password'
          placeholder={t('common.password')}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <div className='EngineLockConfirmPasswordDialog-buttons'>
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button onClick={handleSubmit}>{t('common.submit')}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EngineLockConfirmPasswordDialog;
