import React from 'react';
import ReactInputMask from 'react-input-mask';
import Input from '../Input/Input';
import FormElement from '../FormElement';
import { Props } from './interface';

const InputMask = (props: Props) => {
  const { fullWidth, label, placeholder, value, onChange, className } = props;

  return (
    <FormElement fullWidth={fullWidth} label={label} className={className}>
      <ReactInputMask
        placeholder={placeholder}
        mask='+99999999999999'
        maskChar=''
        value={value}
        onChange={onChange}
      >
        {(inputProps: any) => <Input {...inputProps} fullWidth={fullWidth} />}
      </ReactInputMask>
    </FormElement>
  );
};

export default InputMask;
