import React from 'react';
import Radio from '../../components/Radio';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { IGlobalState } from '../../redux/types';
import { useSelector } from 'react-redux';
import usePending from '../../hooks/usePending';
import { TAutostartConditionValues } from '../../resources/types/devices';
import ConditionValue from './ConditionValue';

const selectAutostartCondition = (state: IGlobalState) =>
  state.devicesInfo.info?.params?.autostart.condition;

interface Props {
  item: TAutostartConditionValues;
}

const AutostartConditionRadio = ({ item }: Props) => {
  const autostartCondition = useSelector(selectAutostartCondition);

  const [pending, onFetch] = usePending(async () =>
    DeviceSettingsController.setAutostartCondition(item)
  );

  return (
    <>
      <Radio
        disabled={pending}
        checked={autostartCondition === item}
        onChange={onFetch}
        name='condition'
      />
      <ConditionValue condition={item} />
    </>
  );
};

export default AutostartConditionRadio;
