import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import AlertReducer from './AlertReducer';
import DevicesInfoReducer from './DevicesInfoReducer';
import AppReducer from './AppReducer';
import LoadingsReducer from './LoadingsReducer';
import TrackingReducer from './TrackingReducer';
import ScheduleReducer from './ScheduleReducer';

export default combineReducers({
  app: AppReducer,
  user: UserReducer,
  alert: AlertReducer,
  devicesInfo: DevicesInfoReducer,
  loadings: LoadingsReducer,
  tracking: TrackingReducer,
  schedule: ScheduleReducer,
});
