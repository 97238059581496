import { AnyAction } from 'redux';
import initialState from '../initialState';
import { CLEAR_ALERT, SHOW_ALERT } from '../actionTypes';

export default function (state = initialState.alert, action: AnyAction) {
  switch (action.type) {
    case SHOW_ALERT:
      return action.value;

    case CLEAR_ALERT:
      return initialState.alert;

    default:
      return state;
  }
}
