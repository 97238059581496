import React from 'react';
import RCSlider from 'rc-slider';
import { Props } from './interface';
import classNames from 'classnames';

import 'rc-slider/dist/rc-slider.css';
import './Slider.scss';

const Slider = ({ className, label, ...props }: Props) => {
  return (
    <div className={classNames('Slider', className)}>
      {label && <div className='Slider-label'>{label}</div>}
      <RCSlider {...props} />
    </div>
  );
};

export default Slider;
