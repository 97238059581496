import React from 'react';
import './DeviceCard.scss';
import Typography from '../Typography';
import { Props } from './interface';
import Grid from '../Grid';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import Svg from '../Svg';
import checkIcon from '../../img/icons/check.svg';
import classNames from 'classnames';
import editIcon from '../../img/icons/edit.svg';
import { formatPhoneNumber } from '../../resources/functions';
import DevicesInfoController from '../../redux/controllers/DevicesInfoController';
import { useTranslation } from 'react-i18next';

const DeviceCard = (props: Props) => {
  const { device, onEdit } = props;

  const deviceId = useSelector(
    (state: IGlobalState) => state.devicesInfo.curDeviceId
  );

  const { t } = useTranslation();

  const isCurrentDevice = String(device.id) === String(deviceId);

  const selectDevice = React.useCallback(() => {
    DevicesInfoController.setCurDevice(device.id);
  }, []);

  const editDevice = React.useCallback((event: any) => {
    event.stopPropagation();
    onEdit();
  }, []);

  return (
    <div className='DeviceCard' onClick={selectDevice}>
      <Grid container justify='space-between'>
        <Grid direction='column' className='DeviceCard-column'>
          <div>
            <Typography weight={500} size={18} color='main'>
              {device.name}
            </Typography>
          </div>
          <div>
            <Typography color='second' size={11}>
              {t('myCars.SIMNumber')}
            </Typography>
            <Typography size={16} color='main'>
              {formatPhoneNumber(device.phone)}
            </Typography>
          </div>
          <div>
            <Typography color='second' size={11}>
              {t('myCars.serialNumber')}
            </Typography>
            <Typography size={16} color='main'>
              {device.emei}
            </Typography>
          </div>
          <div>
            <Typography color='second' size={11}>
              {t('myCars.firmware')}
            </Typography>
            <Typography size={16} color='main'>
              v {device.firmware}
            </Typography>
          </div>
        </Grid>
        <Grid
          align='flex-end'
          container
          className='DeviceCard-rightColumn'
          direction='column'
          justify='space-between'
        >
          <div
            className={classNames(
              'DeviceCard-button',
              isCurrentDevice && 'DeviceCard-button-active'
            )}
          >
            <Svg src={checkIcon} />
            <Typography>
              {isCurrentDevice ? t('myCars.selected') : t('myCars.select')}
            </Typography>
          </div>
          <div
            className='DeviceCard-button DeviceCard-button-active'
            onClick={editDevice}
          >
            <Svg src={editIcon} />
            <Typography>{t('common.edit')}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeviceCard;
