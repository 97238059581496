import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import './Logo.scss';

import logo from '../../img/logo.svg';

const Logo = () => {
  return (
    <Link to='/'>
      <ReactSVG className='Logo' src={logo} />
    </Link>
  );
};

export default Logo;
