import { AnyAction } from 'redux';
import initialState from '../initialState';
import {
  CLOSE_SOCKET,
  INIT_APP,
  INIT_SOCKET,
  SET_BUTTONS,
  SET_SPEED,
  SET_THEME,
} from '../actionTypes';
import { setLocalStorage } from '../../resources/functions';
import { STORAGE_THEME } from '../../resources/storageVariables';

export default function (state = initialState.app, action: AnyAction) {
  switch (action.type) {
    case SET_SPEED:
      return {
        ...state,
        speed: action.payload,
      };

    case SET_BUTTONS:
      return {
        ...state,
        buttons: action.payload,
      };

    case INIT_SOCKET:
      return {
        ...state,
        socket: action.value,
      };

    case CLOSE_SOCKET:
      return {
        ...state,
        socket: null,
      };

    case SET_THEME:
      setLocalStorage(STORAGE_THEME, action.payload);

      return {
        ...state,
        theme: action.payload,
      };

    case INIT_APP:
      return {
        ...state,
        isInit: true,
      };

    default:
      return state;
  }
}
