import React from 'react';
import { Link } from 'react-router-dom';

import Svg from '../Svg';
import Grid from '../Grid';
import ListItem from '../ListItem';
import Typography from '../Typography';
import { Props, TSettingsLinkIcon } from './interface';

import chevronIcon from '../../img/icons/chevronRight.svg';
import info from '../../img/settings/info.svg';
import devises from '../../img/settings/devices.svg';
import remote from '../../img/remote.svg';
import phone from '../../img/settings/phone.svg';
import bell from '../../img/settings/bell.svg';
import a from '../../img/settings/a.svg';
import radio from '../../img/settings/radio.svg';
import ref from '../../img/settings/ref.svg';
import globe from '../../img/settings/globe.svg';
import settings from '../../img/settings/settings.svg';
import dashcam from '../../img/settings/dashcam.svg';
import wrench from '../../img/settings/wrench.svg';
import buttons from '../../img/settings/buttons.svg';
import app from '../../img/settings/app.svg';
import speed from '../../img/deviceInfo/speed.svg';
import moon from '../../img/settings/moon.svg';
import clock from '../../img/settings/clock.svg';
import flow from '../../img/settings/flow.svg';

import './SettingsLink.scss';
import classNames from 'classnames';

const icons: { [key in TSettingsLinkIcon]: string } = {
  info,
  devises,
  remote,
  phone,
  bell,
  a,
  radio,
  ref,
  globe,
  settings,
  wrench,
  dashcam,
  buttons,
  app,
  speed,
  moon,
  clock,
  flow,
};

const SettingsLink = ({
  icon,
  label,
  to,
  isDiv,
  renderAction,
  onClick,
  className,
  hint,
  disabled,
}: Props) => {
  return (
    <ListItem
      onClick={onClick}
      component={isDiv ? 'div' : Link}
      to={`/settings${to}`}
      className={classNames(
        'SettingsLink',
        disabled && 'SettingsLink-disabled',
        className
      )}
    >
      <Grid align='center' container>
        {icon && <Svg src={icons[icon]} />}
        <div className='SettingsLink-labelContainer'>
          <Typography className='SettingsLink-label' color='main' size={14}>
            {label}
          </Typography>
          {hint && (
            <Typography color='third' size={11}>
              {hint}
            </Typography>
          )}
        </div>
        {renderAction ? (
          renderAction({ className: 'SettingsLink-action' })
        ) : (
          <Svg className='SettingsLink-action' src={chevronIcon} />
        )}
      </Grid>
    </ListItem>
  );
};

export default SettingsLink;
