import React from 'react';
import Logo from '../Logo';
import Button from '../Button';
import Grid from '../Grid';
import Typography from '../Typography';
import AppController from '../../redux/controllers/AppController';
import { useTranslation } from 'react-i18next';

import './AppBar.scss';

const AppBar = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    AppController.close();
  };

  return (
    <Grid
      align='center'
      className='AppBar'
      component='header'
      container
      justify='space-between'
    >
      <Logo />

      <Button onClick={handleClick} variant='text'>
        <Typography color='second' size={14} weight={400}>
          {t('common.exit')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default AppBar;
