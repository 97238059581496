import { useEffect, useState } from 'react';
import DevicesInfoController from '../redux/controllers/DevicesInfoController';

const useAlarm = (alarmCondition?: boolean) => {
  const [alarm, setAlarm] = useState(false);

  useEffect(() => {
    let timer: any;

    if (alarmCondition) {
      setAlarm(true);

      timer = window.setTimeout(() => {
        setAlarm(false);
        DevicesInfoController.updateSocketEvents({
          alarm: null,
        });
      }, 40 * 1000);
    } else {
      setAlarm(false);
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [alarmCondition]);

  return alarm;
};

export default useAlarm;
