import React from 'react';
import './MapButton.scss';
import { Props } from './interface';
import Svg from '../Svg';
import classNames from 'classnames';

const MapButton = (props: Props) => {
  const { icon, onClick, className } = props;

  return (
    <div className={classNames('MapButton', className)} onClick={onClick}>
      <Svg src={icon} />
    </div>
  );
};

export default MapButton;
