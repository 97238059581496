import { ReactNode } from 'react';
import { ITrackingPopupProps } from '../TrackingPopup/interface';
import { IconOptions } from 'leaflet';

export enum ETrackingMarkerType {
  start = 's',
  finish = 'f',
  parking = 'p',
}

export interface Props {
  type?: ETrackingMarkerType;
  position: [number, number];
  children?: ReactNode;
  popupData?: ITrackingPopupProps;
  iconOptions?: Partial<IconOptions>;
}
