import React from 'react';
import SettingsLink from '../../components/SettingsLink';
import HeatTimeSettings from '../SettingsPage/HeatTimeSettings';
import './SettingsSystemPage.scss';
import { useTranslation } from 'react-i18next';
import TimeZoneSettings from '../../components/TimeZoneSettings';

const SettingsSystemPage = () => {
  const { t } = useTranslation();

  return (
    <div className='SettingsSystemPage'>
      <SettingsLink
        icon='phone'
        label={t('settings.phoneNumbers')}
        to='/phones'
      />
      <SettingsLink
        icon='a'
        label={t('settings.autostartSettings')}
        to='/autostart'
      />
      <SettingsLink
        icon='radio'
        label={t('settings.sensorsSettings')}
        to='/sensitivity'
      />
      <HeatTimeSettings />
      <TimeZoneSettings />
    </div>
  );
};

export default SettingsSystemPage;
