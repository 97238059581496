import { ISelectOption } from '../components/Select/interface';
import { getUtcStringValue } from './functions';

export const autostartTimeValues = [5, 10, 15, 20, 25, 30, 40, 50, 60] as const;

export const autostartVoltageThresholdValues = [
  10.5, 11, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 12,
] as const;

export const autostartConditionValues = [
  'off',
  'time',
  'external',
  'internal',
] as const;

export const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const;

export const autostartIntervalValues = [
  1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 18, 24,
] as const;

export const autostartTemperatureValues = [
  0, -5, -10, -15, -20, -25, -30,
] as const;

export const heatTime = [10, 15, 20, 25, 30, 40, 50, 60] as const;

export const devicesBodyTypes = ['Седан', 'Кроссовер', 'Внедорожник'];

export const alertMessages = [
  'alarm_ignition',
  'alarm_door',
  'alarm_hood',
  'alarm_trunk',
  'alarm_tilt',
  'alarm_power',
  'alarm_rpm',
  'alarm_external',
  'engine_start_key',
  'engine_stop_key',
  'engine_start_temp',
  'engine_start_time',
  'engine_start_volt',
  'engine_start_keyfob',
  'engine_error_running',
  'engine_start_auto',
  'engine_fail_auto',
  'engine_stop_auto',
  'event_success_auto',
  'event_auto_stopped',
  'event_heat_start',
  'event_heat_stop',
  'lock_secure_enable',
  'lock_secure_disable',
  'event_secure_fail',
  'event_low_voltage',
  'event_service_disable',
  'event_service_enable',
  'event_service_fail',
  'event_sos',
  'event_new_firmware',
  'event_firmware_requirements',
  'event_firmware_success',
  'event_firmware_fail',
  'event_block_in_service',
  'event_block_enable',
  'event_block_disable',
  'wrong_access_code',
  'wrong_command',
  'no_autostart',
  'no_heat',
  'no_secure',
  'no_trunk',
  'no_block',
  'no_gps',
  'no_satellite',
  'no_coords',
  'engine_already_started',
  'engine_already_stopped',
  'heat_already_started',
  'event_exchange_numbers',
  'event_autostart_brake',
  'event_autostart_drive',
  'event_autostart_handbrake',
  'event_no_pin',
  'remote_trunk_open',
  'alarm_motion',
  'event_dashcam_enable',
  'event_dashcam_disable',
  'event_secure_open_door',
  'event_denied_auto',
];

export const bottomAlertMessages = [
  'engine_start_key',
  'engine_stop_key',
  'engine_fail_auto',
  'event_auto_stopped',
  'event_success_auto',
  'event_heat_start',
  'event_heat_stop',
  'lock_secure_enable',
  'lock_secure_disable',
  'event_secure_fail',
  'event_low_voltage',
  'event_service_disable',
  'event_service_enable',
  'event_service_fail',
  'event_block_enable',
  'event_block_disable',
  'event_secure_open_door',
  'event_denied_auto',
];

export const timeZonesOptions: ISelectOption[] = [];

for (let i = -12; i <= 12; i++) {
  const value = getUtcStringValue(i);

  timeZonesOptions.push({
    value: i,
    label: `UTC ${value}`,
  });
}

export const hoursOptions: ISelectOption[] = [];

for (let i = 0; i < 24; i++) {
  const value = i < 10 ? `0${i}` : String(i);
  hoursOptions.push({
    label: value,
    value,
  });
}

export const minutesOptions: ISelectOption[] = [];

for (let i = 0; i < 60; i++) {
  const value = i < 10 ? `0${i}` : String(i);
  minutesOptions.push({
    label: value,
    value,
  });
}
