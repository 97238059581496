import React, { ReactNode, useCallback, useMemo } from 'react';
import EngineButton from '../EngineButton';
import Grid from '../../../../components/Grid';
import { ICarControlButtonProps, TCarControlButtonIcon } from './interface';
import classNames from 'classnames';
import Button from '../../../../components/Button';
import Svg from '../../../../components/Svg';

import trunkIcon from '../../../../img/carControl/trunk.svg';
import webastoIcon from '../../../../img/carControl/heat.svg';
import searchIcon from '../../../../img/carControl/search.svg';
import blockIcon from '../../../../img/carControl/block.svg';
import blockActiveIcon from '../../../../img/carControl/blockActive.svg';
import dashcamIcon from '../../../../img/settings/dashcam.svg';
import auxIcon from '../../../../img/carControl/aux.svg';

import './CarControl.scss';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../../redux/types';
import DeviceControlController from '../../../../redux/controllers/DeviceControlController';
import useClickDelay from '../../../../hooks/useClickDelay';
import { TButtons } from '../../../../resources/types/app';

const icons: { [key in TCarControlButtonIcon]: string } = {
  trunk: trunkIcon,
  webasto: webastoIcon,
  search: searchIcon,
  block: blockIcon,
  blockActive: blockActiveIcon,
  dashcam: dashcamIcon,
  aux: auxIcon,
};

const CarControlButton = ({
  icon,
  active,
  onClick,
  loading,
  isLarge,
  ...props
}: ICarControlButtonProps) => {
  return (
    <Grid
      {...props}
      align='center'
      className={classNames(
        'CarControlButton',
        `CarControlButton-${icon}`,
        active && 'CarControlButton-active',
        isLarge && 'CarControlButton-large'
      )}
      component={Button}
      container
      justify='center'
      loading={loading}
      onClick={onClick}
      variant='text'
    >
      <Svg src={icons[icon]} />
    </Grid>
  );
};

const CarControl = () => {
  const { info, loadings, buttonsStore } = useSelector(
    ({ app, devicesInfo, loadings }: IGlobalState) => ({
      info: devicesInfo.info,
      loadings: loadings,
      buttonsStore: app.buttons,
    })
  );

  const clickDelay = useClickDelay();

  const checkIsLarge = useCallback(
    (button: TButtons) => {
      const match = buttonsStore.find(item => item.name === button);
      return match && match.group === 'main';
    },
    [buttonsStore]
  );

  const checkIsSmall = useCallback(() => {
    const engine = buttonsStore.find(item => item.name === 'engine');
    return engine && engine.group !== 'main';
  }, [buttonsStore]);

  const buttonsConfig = useMemo(() => {
    const main = buttonsStore.find(item => item.group === 'main') || {
      name: 'search',
      group: 'main',
    };
    const small = buttonsStore.filter(item => item.group === 'small');
    return { main, small };
  }, [buttonsStore]);

  const buttons = useMemo<{ [key in TButtons]?: ReactNode }>(() => {
    return {
      trunk: (
        <CarControlButton
          {...clickDelay(() => DeviceControlController.toggleTrunk())}
          active={info?.state?.doors.trunk}
          icon='trunk'
          loading={loadings.trunk}
          isLarge={checkIsLarge('trunk')}
        />
      ),
      heat: (
        <CarControlButton
          {...clickDelay(() => DeviceControlController.toggleHeat())}
          active={Boolean(info?.heat)}
          icon={info?.params?.app.heatType ? 'aux' : 'webasto'}
          loading={loadings.heat}
          isLarge={checkIsLarge('heat')}
        />
      ),
      engine: <EngineButton isSmall={checkIsSmall()} />,
      search: (
        <CarControlButton
          {...clickDelay(() => DeviceControlController.searchCar())}
          icon='search'
          loading={loadings.findCar}
          isLarge={checkIsLarge('search')}
        />
      ),
      block: (
        <CarControlButton
          {...clickDelay(() => DeviceControlController.toggleSecure())}
          active={info?.secure}
          icon={info?.secure ? 'blockActive' : 'block'}
          loading={loadings.secure}
          isLarge={checkIsLarge('block')}
        />
      ),
      dashcam: (
        <CarControlButton
          {...clickDelay(() => DeviceControlController.toggleDashcam())}
          active={info?.dashcam}
          icon='dashcam'
          isLarge={checkIsLarge('dashcam')}
        />
      ),
    };
  }, [clickDelay, loadings, info, checkIsLarge, checkIsSmall]);

  return (
    <Grid
      align='center'
      className='CarControl'
      container
      justify='space-between'
    >
      <Grid
        className='CarControl-buttonsContainer'
        container
        direction='column'
      >
        {buttonsConfig.small[0] && buttons[buttonsConfig.small[0].name]}
        {buttonsConfig.small[1] && buttons[buttonsConfig.small[1].name]}
      </Grid>
      {buttons[buttonsConfig.main.name]}
      <Grid
        className='CarControl-buttonsContainer'
        container
        direction='column'
      >
        {buttonsConfig.small[2] && buttons[buttonsConfig.small[2].name]}
        {buttonsConfig.small[3] && buttons[buttonsConfig.small[3].name]}
      </Grid>
    </Grid>
  );
};

export default CarControl;
