import React from 'react';
import SettingsLink from '../../components/SettingsLink';

import './ReferencePage.scss';
import { useTranslation } from 'react-i18next';

const ReferencePage = () => {
  const { t } = useTranslation();

  return (
    <div className='ReferencePage'>
      <SettingsLink
        label={t('reference.transferToAutoservice.title')}
        to='/ref/transfer_to_autoservice'
      />
      <SettingsLink
        label={t('reference.emergencyDisarming.title')}
        to='/ref/emergency_disarming'
      />
    </div>
  );
};

export default ReferencePage;
