import React, { useCallback } from 'react';
import { Popup } from 'react-leaflet';
import { ITrackingPopupProps } from './interface';
import { EHistoryType } from '../../resources/types/trackingTypes';
import Typography from '../Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TrackingPopup = (props: ITrackingPopupProps) => {
  const { date, lat, lon, type, speed } = props;

  const { t } = useTranslation();

  const localizeType = useCallback(
    (type: EHistoryType) => {
      switch (type) {
        case EHistoryType.start:
          return t('maps.start');
        case EHistoryType.normal:
          return t('maps.motion');
        case EHistoryType.parking:
          return t('maps.parking');
        case EHistoryType.finish:
          return t('maps.finish');
      }
    },
    [t]
  );

  return (
    <Popup>
      <Typography weight={700} size={18} color='main'>
        {localizeType(type)}
      </Typography>
      <br />
      <Typography color='third' size={14}>
        {t('maps.date')}: {moment(date).format('DD.MM HH:mm')}
      </Typography>
      <br />
      <Typography color='third' size={14}>
        {t('maps.speed')}: {speed} {t('common.km/h')}
      </Typography>
      <br />
      <Typography color='third' size={14}>
        {t('maps.lat')}: {lat}
      </Typography>
      <br />
      <Typography color='third' size={14}>
        {t('maps.lon')}: {lon}
      </Typography>
    </Popup>
  );
};

export default TrackingPopup;
