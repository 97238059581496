import React, { useCallback, useState } from 'react';
import Grid from '../../components/Grid';
import SettingsTitle from '../../components/SettingsTitle';
import Button from '../../components/Button';
import DevicesDialog from '../../dialogs/DevicesDialog';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import Wrapper from '../../components/Wrapper';

import './DevicesPage.scss';
import { IDevice } from '../../resources/types/devices';
import DeviceCard from '../../components/DeviceCard';
import DeviceCreatingDialog from '../../dialogs/DeviceCreatingDialog';
import { useTranslation } from 'react-i18next';

const DevicesPage = () => {
  const devices = useSelector(
    ({ devicesInfo }: IGlobalState) => devicesInfo.list
  );

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [device, setDevice] = useState<IDevice | null>(null);
  const onToggleOpen = useCallback(
    (curDevice?: IDevice) => () => {
      if (curDevice) {
        setDevice(curDevice);
      }

      setOpen(!open);
    },
    [open, device]
  );

  const [deviceCreatingDialogOpen, setDeviceCreatingDialog] = useState(false);

  const openDeviceCreatingDialog = useCallback(
    () => setDeviceCreatingDialog(true),
    []
  );

  const closeDeviceCreatingDialog = useCallback(
    () => setDeviceCreatingDialog(false),
    []
  );

  return (
    <Grid container direction='column' className='DevicesPage'>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('settings.myCars')}</SettingsTitle>
        <Button fullWidth onClick={openDeviceCreatingDialog}>
          {t('myCars.addCar')}
        </Button>
      </Wrapper>
      <div className='DevicesPage-divider' />
      <div className='DevicesList'>
        {devices.map(item => (
          <DeviceCard onEdit={onToggleOpen(item)} key={item.id} device={item} />
        ))}
      </div>
      <DevicesDialog device={device} onClose={onToggleOpen()} open={open} />
      <DeviceCreatingDialog
        open={deviceCreatingDialogOpen}
        onClose={closeDeviceCreatingDialog}
      />
    </Grid>
  );
};

export default DevicesPage;
