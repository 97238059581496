import React from 'react';
import Grid from '../Grid';
import Paper from '../Paper';
import Typography from '../Typography';
import Button from '../Button';
import { Props } from './interface';
import './AuthForm.scss';

const AuthForm = (props: Props) => {
  const { title, onSubmit, children, buttonLabel } = props;

  return (
    <Grid className='AuthForm' component={Paper} item>
      <Typography className='AuthForm-title' component='h1'>
        {title}
      </Typography>
      <form className='AuthForm-form' onSubmit={onSubmit}>
        {children}
        <Button
          className='AuthForm-submitButton'
          fullWidth
          size='middle'
          type='submit'
        >
          {buttonLabel}
        </Button>
      </form>
    </Grid>
  );
};

export default AuthForm;
