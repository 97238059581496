import React, { useState } from 'react';
import Grid from '../../components/Grid';
import Logo from '../../components/Logo';
import Typography from '../../components/Typography';
import FormElement from '../../components/FormElement';
import { Link } from 'react-router-dom';
import UserController from '../../redux/controllers/UserController';
import useLegacyState from '../../hooks/useLegacyState';
import { ISignupData } from '../../resources/types/user';
import AlertController from '../../redux/controllers/AlertController';
import { Props } from './interface';

import './RegistrationPage.scss';
import AuthForm from '../../components/AuthForm';
import { useTranslation } from 'react-i18next';
import { getNavigatorLanguage } from '../../resources/functions';

const RegistrationPage = ({ history }: Props) => {
  const { t } = useTranslation();

  const [signUpData, setData] = useLegacyState<ISignupData>({
    email: '',
    login: '',
    password: '',
    language: getNavigatorLanguage(),
  });

  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChange = (name: keyof ISignupData | 'confirmPassword') => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    } else {
      setData({ [name]: e.target.value });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (signUpData.password === confirmPassword) {
      UserController.signUp(signUpData, () => history.push('/'));
    } else {
      AlertController.show(t('restorePassword.notMatch'), 'error');
    }
  };

  return (
    <Grid
      align='center'
      className='RegistrationPage'
      container
      direction='column'
      justify='center'
    >
      <Logo />

      <AuthForm
        title={t('registration.title')}
        onSubmit={handleSubmit}
        buttonLabel={t('logIn.register')}
      >
        <FormElement
          fullWidth
          label={t('common.login')}
          onChange={handleChange('login')}
          placeholder={`${t('common.enter')} ${t('common.login')}`}
          required
          value={signUpData.login}
        />
        <FormElement
          fullWidth
          inputProps={{
            type: 'email',
          }}
          label={t('common.email')}
          onChange={handleChange('email')}
          placeholder={`${t('common.enter')} ${t('common.email')}`}
          required
          value={signUpData.email}
        />
        <FormElement
          fullWidth
          inputProps={{
            type: 'password',
          }}
          label={t('common.password')}
          onChange={handleChange('password')}
          placeholder={`${t('common.enter')} ${t('common.password')}`}
          required
          value={signUpData.password}
        />
        <FormElement
          fullWidth
          inputProps={{
            type: 'password',
          }}
          label={t('restorePassword.repeatPassword')}
          onChange={handleChange('confirmPassword')}
          placeholder={t('restorePassword.repeatPassword')}
          required
          value={confirmPassword}
        />
      </AuthForm>

      <Grid align='center' container direction='column'>
        <Typography
          color='red'
          component={Link}
          size={18}
          textDecoration='none'
          to='/login'
          weight={600}
        >
          {t('restorePassword.logIn')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RegistrationPage;
