import React from 'react';
import classNames from 'classnames';
import { IInputProps } from './interface';

import './Input.scss';

const Input = ({ className, fullWidth, ...props }: IInputProps) => {
  const cn = classNames('Input', className, {
    'Input-fullWidth': fullWidth,
  });

  return <input {...props} className={cn} />;
};

export default Input;
