import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import Grid from '../../components/Grid';
import ListItem from '../../components/ListItem';
import Indicator from './Indicator';
import Typography from '../../components/Typography';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const HistoryEvents = () => {
  const { data } = useSelector(
    ({ devicesInfo }: IGlobalState) => devicesInfo.events
  );

  const { t } = useTranslation();

  return (
    <div className='HistoryPage-eventsList'>
      <Grid container direction='column'>
        {data.map(item => (
          <ListItem
            key={`${item.ts}-${item.event}`}
            component={Grid}
            container
            align='center'
            className='HistoryPage-event'
          >
            <Indicator type={item.type} />
            <Grid
              direction='column'
              container
              className='HistoryPage-event-info'
            >
              <Typography color='main' size={14} component='span'>
                {t(`events.${item.event}` as any)}
              </Typography>
              <Typography size={12} color='second' component='span'>
                {moment(item.ts).format('DD MMMM YYYY HH:mm')}
              </Typography>
            </Grid>
          </ListItem>
        ))}
      </Grid>
    </div>
  );
};

export default HistoryEvents;
