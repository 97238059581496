import React from 'react';
import classNames from 'classnames';
import { Props, TFormElementType } from './interface';

import Input from '../Input/Input';
import Typography from '../Typography';

import './FormElement.scss';

const getElement = (type?: TFormElementType) => {
  switch (type) {
    case 'input':
    default:
      return Input;
  }
};

const FormElement = ({
  type,
  className,
  label,
  inputProps,
  children,
  ...props
}: Props) => {
  const Component = getElement(type);

  const cn = classNames('FormElement', className, {
    'FormElement-fullWidth': props.fullWidth,
  });

  return (
    <div className={cn}>
      <label className='FormElement-label'>
        <Typography color='third'>{label}</Typography>
      </label>
      {children || <Component {...inputProps} {...(props as any)} />}
    </div>
  );
};

export default FormElement;
