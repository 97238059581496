import React, { useCallback, useMemo, useState } from 'react';
import SettingsLink from '../SettingsLink';
import { useTranslation } from 'react-i18next';
import DialogList from '../DialogList';
import Typography from '../Typography';
import './LanguageSelect.scss';
import AppController from '../../redux/controllers/AppController';
import UserController from '../../redux/controllers/UserController';

const languages = [
  {
    label: 'Eesti',
    value: 'ee',
  },
  {
    label: 'Український',
    value: 'ua',
  },
  {
    label: 'Русский',
    value: 'ru',
  },
  {
    label: 'English',
    value: 'en',
  },
];

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = useCallback(() => setDialogOpen(true), []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  const changeLanguage = useCallback((value: string) => {
    UserController.changeLanguage(value, token => {
      UserController.setToken(token);
      AppController.reinit();
    });
  }, []);

  const languageLabel = useMemo(
    () => languages.find(item => item.value === i18n.language)?.label,
    [i18n.language]
  );

  return (
    <>
      <SettingsLink
        onClick={openDialog}
        icon='globe'
        label={t('settings.language')}
        isDiv
        renderAction={() => (
          <Typography size={14} color='main' className='LanguageSelect-label'>
            {languageLabel}
          </Typography>
        )}
      />
      <DialogList
        open={dialogOpen}
        onClose={closeDialog}
        items={languages}
        onChange={changeLanguage}
      />
    </>
  );
};

export default LanguageSelect;
