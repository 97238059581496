import React, { RefObject, useCallback, useState } from 'react';
import Grid from '../Grid';
import NavBarLink from '../NavBarLink';

import './NavBar.scss';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const { t } = useTranslation();

  const [currentLink, setCurrentLink] = useState<RefObject<
    HTMLLinkElement
  > | null>(null);
  const onChange = useCallback((ref: RefObject<HTMLLinkElement>) => {
    setCurrentLink(ref);
  }, []);

  const getStatusStyle = useCallback(() => {
    return {
      left: currentLink?.current?.offsetLeft || 0,
      width: currentLink?.current?.offsetWidth,
    };
  }, [currentLink]);

  return (
    <Grid className='NavBar' component='nav' container>
      <NavBarLink
        exact
        icon='control'
        label={t('nav.control')}
        onChange={onChange}
        to='/'
      />
      <NavBarLink
        icon='history'
        label={t('nav.history')}
        onChange={onChange}
        to='/history'
      />
      <NavBarLink
        icon='settings'
        label={t('nav.settings')}
        onChange={onChange}
        to='/settings'
      />
      <div className='NavBar-status' style={getStatusStyle()} />
    </Grid>
  );
};

export default NavBar;
