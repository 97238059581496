import React from 'react';
import classNames from 'classnames';
import { Props, TButtonIcon } from './interface';
import { ucFirst } from '../../resources/functions';
import Svg from '../Svg';
import Spinner from '../Spinner';

import engineBlockIcon from '../../img/icons/engineBlock.svg';

import './Button.scss';

const icons: { [key in TButtonIcon]: string } = {
  engineBlock: engineBlockIcon,
};

function Button<T>({
  children,
  className,
  fullWidth,
  variant = 'contained',
  component,
  size,
  color,
  startIcon,
  endIcon,
  loading,
  disabled,
  ...props
}: Props<T>) {
  const cn = classNames(
    'Button',
    className,
    `Button-${variant}${color ? ucFirst(color) : ''}`,
    {
      'Button-disabled': disabled,
      'Button-loading': loading,
      'Button-fullWidth': fullWidth,
      [`Button-size-${size}`]: size,
    }
  );

  const Component = component || 'button';

  // @ts-ignore
  const injectedProps: T = props;

  return (
    <Component {...injectedProps} className={cn} disabled={disabled || loading}>
      {startIcon && <Svg className='Button-startIcon' src={icons[startIcon]} />}
      {children}
      {endIcon && <Svg className='Button-endIcon' src={icons[endIcon]} />}
      <Spinner show={loading} />
    </Component>
  );
}

export default Button;
