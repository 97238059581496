import React, { useCallback, useContext, useMemo } from 'react';
import dateIcon from '../../img/map/date.svg';
import statsIcon from '../../img/map/stats.svg';
import closeIcon from '../../img/map/close.svg';
import geolocationIcon from '../../img/map/geolocation.svg';
import routeIcon from '../../img/map/route.svg';
import MapButton from '../MapButton';
import TrackingMapContext from '../TrackingMapContext';
import { useMap } from 'react-leaflet';

const TrackingMapButtons = () => {
  const context = useContext(TrackingMapContext);
  const {
    center,
    openDatepicker,
    setShowRoute,
    showRoute,
    openStatsDialog,
  } = context;

  const map = useMap();

  const buttons = useMemo(
    () => [
      {
        hide: !showRoute,
        name: 'date',
        icon: dateIcon,
        onClick: openDatepicker,
      },
      {
        hide: !showRoute,
        name: 'stats',
        icon: statsIcon,
        onClick: openStatsDialog,
      },
      {
        hide: !showRoute,
        name: 'close',
        icon: closeIcon,
        onClick: () => {
          setShowRoute(false);
        },
      },
      {
        hide: showRoute,
        name: 'geolocation',
        icon: geolocationIcon,
        onClick: () => {
          if (center) {
            map.setView(center, map.getZoom());
          }
        },
      },
      {
        hide: showRoute,
        name: 'route',
        icon: routeIcon,
        onClick: () => {
          setShowRoute(true);
          openDatepicker();
        },
      },
    ],
    [showRoute, openDatepicker, map, openStatsDialog, center]
  );

  const renderButtons = useCallback(() => {
    return buttons.map(button => {
      if (button.hide) {
        return null;
      }

      return (
        <MapButton
          onClick={button.onClick}
          key={button.name}
          icon={button.icon}
          className='TrackingMap-button'
        />
      );
    });
  }, [buttons]);

  return <div className='TrackingMap-buttonsContainer'>{renderButtons()}</div>;
};

export default TrackingMapButtons;
