import Controller from './Controller';
import axios, { SOCKET_URL } from '../../lib/axios';
import DevicesInfoController from './DevicesInfoController';
import {
  CLOSE_SOCKET,
  SET_CURRENT_COORDS,
  INIT_SOCKET,
  SET_BUTTONS,
  SET_SPEED,
  UPDATE_CAN_STATE,
  UPDATE_PARAMS,
  UPDATE_STATE,
  CLEAR_STORE,
  SET_THEME,
  INIT_APP,
} from '../actionTypes';
import io from 'socket.io-client';
import {
  IDeviceInfoCanState,
  IDeviceInfoParams,
  IDeviceInfoState,
} from '../../resources/types/devices';
import { ISocketEvent } from '../../resources/types/sockets';
import LoadingsController from './LoadingsController';
import UserController from './UserController';
import { ESpeed, IButton, TButtons, TTheme } from '../../resources/types/app';
import { setLocalStorage } from '../../resources/functions';
import {
  STORAGE_BUTTONS,
  STORAGE_SPEED,
} from '../../resources/storageVariables';
import { ITrackingCurrent } from '../../resources/types/trackingTypes';
import {
  alertMessages,
  bottomAlertMessages,
} from '../../resources/constVariables';
import { IGlobalState } from '../types';

class AppController extends Controller {
  checkButtonsState() {
    const buttons = this.getState().app.buttons;
    const deviceInfo = this.getState().devicesInfo.info;
    const deviceParamsApp =
      deviceInfo?.params?.app || ({} as IDeviceInfoParams['app']);

    const nextButtons = buttons.slice();

    const toggleButton = (name: TButtons, active?: boolean) => {
      const matchIndex = nextButtons.findIndex(item => item.name === name);
      if (active && matchIndex === -1) {
        nextButtons.push({ name, group: 'unused' });
      } else if (!active && matchIndex !== -1) {
        nextButtons.splice(matchIndex, 1);
      }
    };

    toggleButton('block', deviceParamsApp.secureButton);
    toggleButton('trunk', deviceParamsApp.trunkButton);
    toggleButton('heat', deviceParamsApp.heatButton);
    toggleButton('dashcam', deviceParamsApp.dashcam);
    toggleButton('engine', deviceInfo?.params?.autostart.enabled);

    const hasMain = nextButtons.find(item => item.group === 'main');

    if (!hasMain) {
      toggleButton('search', false);
      nextButtons.push({ name: 'search', group: 'main' });
    }

    this.setButtons(nextButtons);
  }

  setButtons(buttons: IButton[]) {
    setLocalStorage(STORAGE_BUTTONS, buttons);
    this.dispatch({ type: SET_BUTTONS, payload: buttons });
  }

  setSpeed(speed: ESpeed) {
    setLocalStorage(STORAGE_SPEED, speed);
    this.dispatch({ type: SET_SPEED, payload: speed });
  }

  attachToken() {
    const userState = this.getState().user.auth;

    if (userState) {
      const { accessToken } = userState;

      if (accessToken) {
        axios.defaults.headers.common['x-access-token'] = accessToken;
        return;
      }
    }
  }

  deattachToken() {
    delete axios.defaults.headers.common['x-access-token'];
  }

  alertTimer: NodeJS.Timeout | undefined;

  bottomAlertTimer: NodeJS.Timeout | undefined;

  openSocket() {
    const state = this.getState();
    const userState = state.user.auth;

    if (userState) {
      const { accessToken } = userState;

      if (accessToken) {
        const socket = io(SOCKET_URL, {
          query: {
            token: accessToken,
          },
        });

        socket.on('state', (data: IDeviceInfoState & { deviceId: string }) => {
          const { deviceId, ...value } = data;

          if (deviceId === this.deviceId) {
            this.dispatch({
              type: UPDATE_STATE,
              value,
            });
          }
        });

        socket.on(
          'canState',
          (data: IDeviceInfoCanState & { deviceId: string }) => {
            const { deviceId, ...value } = data;

            if (deviceId === this.deviceId) {
              this.dispatch({
                type: UPDATE_CAN_STATE,
                value,
              });
            }
          }
        );

        socket.on(
          'params',
          (data: IDeviceInfoParams & { deviceId: string }) => {
            const { deviceId, ...value } = data;

            if (deviceId === this.deviceId) {
              this.dispatch({
                type: UPDATE_PARAMS,
                value,
              });
              this.checkButtonsState();
            }
          }
        );

        socket.on('event', (event: ISocketEvent) => {
          if (event.deviceId !== this.deviceId) return;

          if (alertMessages.includes(event.event)) {
            if (this.alertTimer) {
              clearTimeout(this.alertTimer);
            }

            DevicesInfoController.setAlertMessage(event.event);
            this.alertTimer = setTimeout(() => {
              DevicesInfoController.setAlertMessage('');
            }, 30000);
          }

          if (bottomAlertMessages.includes(event.event)) {
            if (this.bottomAlertTimer) {
              clearTimeout(this.bottomAlertTimer);
            }

            DevicesInfoController.setBottomAlertMessage(event.event);
            this.bottomAlertTimer = setTimeout(() => {
              DevicesInfoController.setBottomAlertMessage('');
            }, 10000);
          }

          switch (event.event) {
            case 'event_block_enable':
            case 'event_block_disable':
              LoadingsController.setEngineBlock();
              break;

            case 'event_heat_start':
              DevicesInfoController.updateDeviceInfo({
                heat: new Date().toString(),
              });
              LoadingsController.setHeat();
              break;
            case 'event_heat_stop':
              DevicesInfoController.updateDeviceInfo({ heat: null });
              LoadingsController.setHeat();
              break;

            case 'alarm_tilt':
            case 'alarm_motion':
            case 'alarm_ignition':
            case 'alarm_door':
            case 'alarm_trunk':
            case 'alarm_hood':
            case 'alarm_hit':
              DevicesInfoController.updateSocketEvents({
                alarm: event.event,
              });
              break;

            case 'lock_secure_enable':
              DevicesInfoController.updateDeviceInfo({ secure: true });
              break;

            case 'lock_secure_disable':
              DevicesInfoController.updateDeviceInfo({ secure: false });
              break;

            case 'engine_start_auto':
              DevicesInfoController.getDeviceInfo(this.deviceId);
              break;

            case 'event_success_auto':
              DevicesInfoController.updateDeviceInfo({ engineAutostart: '' });
              break;

            case 'event_service_enable':
              DevicesInfoController.updateDeviceInfo({ service: true });
              break;

            case 'event_service_disable':
              DevicesInfoController.updateDeviceInfo({ service: false });
              break;

            case 'engine_start_key':
              DevicesInfoController.updateDeviceInfo({
                lastEngineEvent: 'engine_start_key',
              });
              break;

            case 'engine_stop_key':
              DevicesInfoController.updateDeviceInfo({
                lastEngineEvent: 'engine_stop_key',
              });
              break;

            case 'event_device_offline':
              DevicesInfoController.updateDeviceInfo({
                online: false,
              });
              break;

            case 'event_device_online':
              DevicesInfoController.updateDeviceInfo({
                online: true,
              });
              break;

            case 'event_dashcam_enable':
              DevicesInfoController.updateDeviceInfo({
                dashcam: true,
              });
              break;

            case 'event_dashcam_disable':
              DevicesInfoController.updateDeviceInfo({
                dashcam: false,
              });
              break;

            default:
              break;
          }
        });

        socket.on('coordEvent', (data: ITrackingCurrent) => {
          if (data.deviceId === this.deviceId) {
            this.dispatch({
              type: SET_CURRENT_COORDS,
              payload: { data },
            });
          }
        });

        this.dispatch({
          type: INIT_SOCKET,
          value: socket,
        });
      }
    }
  }

  closeSocket() {
    const stateSocket = this.getState().app.socket;

    if (stateSocket) {
      stateSocket.close();

      this.dispatch({
        type: CLOSE_SOCKET,
      });
    }
  }

  init() {
    this.openSocket();
    this.attachToken();
    UserController.getInfo();
    UserController.getSettings();
    DevicesInfoController.getDevices();
    this.dispatch({ type: INIT_APP });
  }

  reinit() {
    this.closeSocket();
    this.openSocket();
    this.attachToken();
  }

  close() {
    this.closeSocket();
    this.deattachToken();
    UserController.signOut();
    this.dispatch({ type: CLEAR_STORE });
  }

  setTheme(theme: TTheme) {
    this.dispatch({ type: SET_THEME, payload: theme });
  }
}

export const selectTheme = (state: IGlobalState) => state.app.theme;

export default new AppController();
