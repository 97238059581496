import React from 'react';
import { DialogContentProps } from './interface';
import classNames from 'classnames';

const DialogContent = ({ children, className }: DialogContentProps) => {
  return (
    <div className={classNames('DialogContent', className)}>{children}</div>
  );
};

export default DialogContent;
