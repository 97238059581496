import React from 'react';
import classNames from 'classnames';
import { Props } from './interface';

import './ListItem.scss';

function ListItem<T>({ children, component, className, ...props }: Props<T>) {
  const Component = component || 'div';

  // @ts-ignore
  const injectedProps: T = props;

  return (
    <Component {...injectedProps} className={classNames('ListItem', className)}>
      {children}
    </Component>
  );
}

export default ListItem;
