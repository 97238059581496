import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { Props } from './interface';
import { CSSTransition } from 'react-transition-group';

import './Spinner.scss';

const Spinner = ({ show }: Props) => {
  return (
    <CSSTransition
      classNames='Spinner-transition'
      in={show}
      timeout={200}
      unmountOnExit
    >
      <div className='Spinner'>
        <PulseLoader color='#EB353D' loading margin={2} size={8} />
      </div>
    </CSSTransition>
  );
};

export default Spinner;
