import Controller from './Controller';
import { TAlertType } from '../../resources/types/alert';
import { CLEAR_ALERT, SHOW_ALERT } from '../actionTypes';

class AlertController extends Controller {
  show(message: string, type: TAlertType) {
    this.dispatch({
      type: SHOW_ALERT,
      value: {
        message,
        type,
      },
    });
  }

  clear() {
    this.dispatch({
      type: CLEAR_ALERT,
    });
  }
}

export default new AlertController();
