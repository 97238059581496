import React from 'react';
import DevicesPage from '../../pages/DevicesPage';
import { Route, Switch } from 'react-router-dom';
import SettingsBack from '../SettingsBack';
import Grid from '../Grid';
import AutostartSettingsPage from '../../pages/AutostartSettingsPage';
import CommonInfoPage from '../../pages/CommonInfoPage';
import SensitivitySettingsPage from '../../pages/SensitivitySettingsPage';
import ReferencePage from '../../pages/ReferencePage';
import ReferenceTypePage from '../../pages/ReferenceTypePage';
import './SettingsRoutes.scss';
import SettingsSystemPage from '../../pages/SettingsSystemPage';
import SettingsPhonesPage from '../../pages/SettingsPhonesPage';
import ButtonsSettingsPage from '../../pages/ButtonsSettingsPage';
import AppSettingsPage from '../../pages/AppSettingsPage';
import SchedulePage from '../../pages/SchedulePage';

const routes = [
  {
    path: '/settings/info',
    component: CommonInfoPage,
  },
  {
    path: '/settings/devises',
    component: DevicesPage,
  },
  {
    path: '/settings/autostart',
    component: AutostartSettingsPage,
    backTo: '/settings/system',
  },
  {
    path: '/settings/schedule',
    component: SchedulePage,
    backTo: '/settings/autostart',
  },
  {
    path: '/settings/sensitivity',
    component: SensitivitySettingsPage,
    backTo: '/settings/system',
  },
  {
    path: '/settings/ref',
    component: ReferencePage,
  },
  {
    path: '/settings/ref/:type',
    component: ReferenceTypePage,
    backTo: '/settings/ref',
  },
  {
    path: '/settings/system',
    component: SettingsSystemPage,
  },
  {
    path: '/settings/phones',
    component: SettingsPhonesPage,
    backTo: '/settings/system',
  },
  {
    path: '/settings/app',
    component: AppSettingsPage,
  },
  {
    path: '/settings/buttons',
    component: ButtonsSettingsPage,
    backTo: '/settings/app',
  },
];

const SettingsRoutes = () => {
  return (
    <Grid className='SettingsRoutes' container direction='column'>
      <Switch>
        {routes.map(route => (
          <Route
            key={route.path}
            exact
            path={route.path}
            render={props => {
              const Component = route.component;

              return (
                <>
                  <SettingsBack to={route.backTo || '/settings'} />
                  <Component {...props} />
                </>
              );
            }}
          />
        ))}
      </Switch>
    </Grid>
  );
};

export default SettingsRoutes;
