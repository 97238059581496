import React, { useCallback } from 'react';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/Dialog';
import { IChangePasswordDialogProps } from './interface';
import FormElement from '../../components/FormElement';
import useFormInput from '../../hooks/useFormInput';
import Button from '../../components/Button';
import Typography from '../../components/Typography';

import './ChangePasswordDialog.scss';
import UserController from '../../redux/controllers/UserController';
import { useTranslation } from 'react-i18next';

const ChangePasswordDialog = ({
  ...dialogProps
}: IChangePasswordDialogProps) => {
  const { t } = useTranslation();

  const password = useFormInput('');
  const newPassword = useFormInput('');

  const handleSubmit = useCallback(() => {
    UserController.changePassword(
      {
        oldPassword: password.value,
        newPassword: newPassword.value,
      },
      () => {
        dialogProps.onClose();
      }
    );
  }, [password, newPassword]);

  return (
    <Dialog
      {...dialogProps}
      maxWidth='small'
      fullWidth
      className='ChangePasswordDialog'
    >
      <DialogTitle>
        <Typography color='main' size={24} weight={700}>
          {t('common.passwordChange')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormElement
          {...password}
          fullWidth
          label={t('common.password')}
          inputProps={{ type: 'password', autoComplete: 'new-password' }}
        />
        <FormElement
          {...newPassword}
          fullWidth
          label={t('common.newPassword')}
          inputProps={{ type: 'password', autoComplete: 'new-password' }}
        />
      </DialogContent>
      <DialogActions>
        <Button size='middle' fullWidth onClick={handleSubmit}>
          {t('common.changePassword')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
