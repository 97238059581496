import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';

import './ClickDelayLoader.scss';
import { ClickDelayContextProps } from './interface';

const ClickDelayContext = React.createContext<ClickDelayContextProps>({
  visible: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  setVisible: value => undefined,
});

const ClickDelayLoader = ({ visible }: { visible: boolean }) => {
  return (
    <div
      className={classNames(
        'ClickDelayLoader',
        visible && 'ClickDelayLoader-visible'
      )}
    >
      <div className='ClickDelayLoader-progress' />
    </div>
  );
};

export const ClickDelayProvider = ({ children }: { children: ReactNode }) => {
  const [visible, setVisible] = useState(false);

  return (
    <ClickDelayContext.Provider
      value={{ visible, setVisible: (value: boolean) => setVisible(value) }}
    >
      <div className='ClickDelayLoader-wrapper'>
        <ClickDelayLoader visible={visible} />
        {children}
      </div>
    </ClickDelayContext.Provider>
  );
};

export default ClickDelayContext;
