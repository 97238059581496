import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Grid from '../../components/Grid';
import SettingsTitle from '../../components/SettingsTitle';
import Wrapper from '../../components/Wrapper';
import Svg from '../../components/Svg';
import Typography from '../../components/Typography';
import { TButtonsConfig } from './interface';
import ListItem from '../../components/ListItem';
import Button from '../../components/Button';

import trunkIcon from '../../img/carControl/trunk.svg';
import blockIcon from '../../img/carControl/block.svg';
import searchIcon from '../../img/carControl/search.svg';
import heatIcon from '../../img/carControl/heat.svg';
import dashcamIcon from '../../img/settings/dashcam.svg';
import burgerIcon from '../../img/burger.svg';
import engineIcon from '../../img/carControl/autostart.svg';
import auxIcon from '../../img/carControl/aux.svg';
import { IButton, TButtonGroups, TButtons } from '../../resources/types/app';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import Sortable from 'sortablejs';

import './ButtonsSettingsPage.scss';
import AppController from '../../redux/controllers/AppController';
import { useTranslation } from 'react-i18next';

const ButtonItem = (props: { buttonName: TButtons }) => {
  const { buttonName } = props;

  const info = useSelector((state: IGlobalState) => state.devicesInfo.info);

  const { t } = useTranslation();

  const buttons = useMemo<TButtonsConfig>(() => {
    const isAux = info?.params?.app.heatType;

    return {
      trunk: {
        icon: trunkIcon,
        label: t('buttonsSettings.trunk'),
      },
      block: {
        icon: blockIcon,
        label: t('buttonsSettings.close'),
      },
      search: {
        icon: searchIcon,
        label: t('buttonsSettings.search'),
      },
      heat: {
        icon: isAux ? auxIcon : heatIcon,
        label: isAux ? 'AUX' : t('buttonsSettings.heat'),
      },
      engine: {
        icon: engineIcon,
        label: t('buttonsSettings.start'),
      },
      dashcam: {
        icon: dashcamIcon,
        label: t('buttonsSettings.dashcam'),
      },
    };
  }, [info, t]);

  const buttonConfig = buttons[buttonName];

  return (
    <Grid
      id={buttonName}
      tabIndex={0}
      component={ListItem}
      container
      align='center'
      className={`ButtonsSettingsList-ListItem ButtonsSettingsList-ButtonItem ButtonsSettingsList-ButtonItem-${buttonName}`}
    >
      <Svg
        className='ButtonsSettingsList-ButtonItem-icon'
        src={buttonConfig.icon}
      />
      <Typography color='main' size={14} weight={400}>
        {buttonConfig.label}
      </Typography>
      <Svg src={burgerIcon} className='ButtonsSettingsList-ButtonItem-burger' />
    </Grid>
  );
};

const GroupLabel = (props: { label: string }) => {
  const { label } = props;

  return (
    <Grid
      container
      component={ListItem}
      className='ButtonsSettingsList-ListItem ButtonsSettingsList-GroupLabel'
    >
      <Typography color='third' size={14} weight={400}>
        {label}
      </Typography>
    </Grid>
  );
};

const ButtonsSettingsPage = () => {
  const { buttonsStore } = useSelector((state: IGlobalState) => ({
    buttonsStore: state.app.buttons,
  }));

  const { t } = useTranslation();

  const mainList = useRef<HTMLDivElement>(null);
  const smallList = useRef<HTMLDivElement>(null);
  const unusedList = useRef<HTMLDivElement>(null);

  const stopUpdateLocalButtonStore = useRef(false);

  const [localButtonsState, setLocalButtonsState] = useState(buttonsStore);

  useEffect(() => {
    if (!stopUpdateLocalButtonStore.current) {
      setLocalButtonsState(buttonsStore);
    }
  }, [buttonsStore]);

  const filterButtonsByGroup = useCallback(
    (buttons: IButton[], group: TButtonGroups) =>
      buttons.filter(item => item.group === group),
    []
  );

  const mainButtons = useMemo(
    () => filterButtonsByGroup(localButtonsState, 'main'),
    [localButtonsState]
  );
  const smallButtons = useMemo(
    () => filterButtonsByGroup(localButtonsState, 'small'),
    [localButtonsState]
  );
  const unusedButtons = useMemo(
    () => filterButtonsByGroup(localButtonsState, 'unused'),
    [localButtonsState]
  );

  useEffect(() => {
    if (mainList.current && smallList.current && unusedList.current) {
      Sortable.create(mainList.current, {
        group: {
          name: 'buttons',
          pull: false,
        },
        onAdd: event => {
          let oldItem = null;

          Array.from(event.to.children).forEach(el => {
            if (el.id !== event.item.id) {
              oldItem = el;
            }
          });

          if (oldItem) {
            event.from.insertBefore(oldItem, event.from.firstChild);
          }
        },
      });

      Sortable.create(smallList.current, {
        group: {
          name: 'buttons',
        },
        onAdd: event => {
          if (event.to.children.length > 4) {
            let lastOldItem = null;

            Array.from(event.to.children).forEach(el => {
              if (el.id !== event.item.id) {
                lastOldItem = el;
              }
            });

            if (lastOldItem) {
              event.from.insertBefore(lastOldItem, event.from.firstChild);
            }
          }
        },
      });

      Sortable.create(unusedList.current, {
        group: 'buttons',
      });
    }
  }, []);

  const handleSave = useCallback(() => {
    if (mainList.current && smallList.current && unusedList.current) {
      stopUpdateLocalButtonStore.current = true;
      AppController.setButtons([
        { name: mainList.current.children[0].id, group: 'main' },
        ...Array.from(smallList.current.children).map(el => ({
          name: el.id,
          group: 'small',
        })),
        ...Array.from(unusedList.current.children).map(el => ({
          name: el.id,
          group: 'unused',
        })),
      ] as IButton[]);
    }
  }, []);

  const renderListItems = useCallback(
    (list: IButton[]) =>
      list.map(item => <ButtonItem key={item.name} buttonName={item.name} />),
    []
  );

  return (
    <Grid container direction='column' className='ButtonsSettingsPage'>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('settings.buttonsSettings')}</SettingsTitle>
      </Wrapper>
      <GroupLabel label={t('buttonsSettings.largeButton')} />
      <div ref={mainList}>{renderListItems(mainButtons)}</div>
      <GroupLabel label={t('buttonsSettings.smallButtons')} />
      <div ref={smallList}>{renderListItems(smallButtons)}</div>
      <GroupLabel label={t('buttonsSettings.unusedButtons')} />
      <div ref={unusedList}>{renderListItems(unusedButtons)}</div>
      <Button onClick={handleSave} className='ButtonsSettingsPage-saveButton'>
        {t('common.save')}
      </Button>
    </Grid>
  );
};

export default ButtonsSettingsPage;
