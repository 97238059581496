import React, { useCallback, useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';

import Dialog, { DialogActions } from '../Dialog';
import Svg from '../Svg';

import chevronDown from '../../img/icons/chevronDown.svg';

import './RangeDatePicker.scss';
import Button from '../Button';
import { Props } from './interface';
import { checkSameDate } from '../../resources/functions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../redux/controllers/AppController';
import useDateFnsLocale from '../../hooks/useDateFnsLocale';

const getDate = (date: Date | null) => {
  return moment(date || undefined).format('DD MMM');
};

const RangeDatePicker = ({ startDate, endDate, onChange }: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const { locale } = useDateFnsLocale();

  const [state, setState] = useState([
    {
      startDate,
      endDate,
      key: 'selection',
    },
  ]);

  const [show, setShow] = useState(false);
  const onToggleShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  const handleChange = useCallback((item: any) => {
    const { startDate, endDate } = item.selection;
    const nextState = {
      startDate: new Date(new Date(startDate).setHours(0, 0, 0, 0)),
      endDate: new Date(new Date(endDate).setHours(23, 59, 59, 0)),
      key: 'selection',
    };
    setState([nextState]);
  }, []);

  const onToday = useCallback(() => {
    state[0].startDate = new Date(new Date().setHours(0, 0, 0, 0));
    state[0].endDate = new Date(new Date().setHours(23, 59, 59, 0));
    setState(state);
    onChange(state[0].startDate, state[0].endDate);
    onToggleShow();
  }, [onToggleShow, onChange, state]);

  const onSubmit = useCallback(() => {
    onChange(state[0].startDate, state[0].endDate);
    onToggleShow();
  }, [onToggleShow, onChange, state]);

  return (
    <div className='RangeDatePicker'>
      <div className='RangeDatePicker-input' onClick={onToggleShow}>
        {getDate(startDate)}{' '}
        {endDate &&
          startDate &&
          !checkSameDate(startDate, endDate) &&
          `- ${getDate(endDate)}`}
        <Svg src={chevronDown} className='RangeDatePicker-icon' />
      </div>
      <Dialog
        className='RangeDatePickerDialog'
        onClose={onToggleShow}
        open={show}
      >
        <DateRange
          className='Datepicker'
          editableDateInputs={true}
          endDatePlaceholder={t('common.to')}
          locale={locale}
          moveRangeOnFirstSelection={false}
          onChange={handleChange}
          rangeColors={[theme === 'dark' ? '#e65100' : '#EB353D']}
          ranges={state}
          startDatePlaceholder={t('common.from')}
          weekdayDisplayFormat='EEEEEE'
        />
        <DialogActions>
          <Button
            className='RangeDatePickerDialog-today Datepicker-today'
            onClick={onToday}
            variant='text'
          >
            {t('common.today')}
          </Button>
          <Button
            className='RangeDatePickerDialog-cancel Datepicker-cancel'
            onClick={onToggleShow}
            variant='text'
          >
            {t('common.cancel')}
          </Button>
          <Button
            className='RangeDatePickerDialog-submit Datepicker-submit'
            onClick={onSubmit}
            variant='text'
          >
            {t('common.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RangeDatePicker;
