import React from 'react';
import classNames from 'classnames';
import { AlertComponentPropsWithStyle } from 'react-alert';

import Button from '../Button';

import './AlertTemplate.scss';
import { useTranslation } from 'react-i18next';

const AlertTemplate = ({
  style,
  options,
  message,
  close,
}: AlertComponentPropsWithStyle) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'AlertTemplate',
        `AlertTemplate-type-${options.type}`
      )}
      style={style}
    >
      {message}
      <Button onClick={close} variant='text'>
        {t('common.close')}
      </Button>
    </div>
  );
};

export default AlertTemplate;
