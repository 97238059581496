import React from 'react';
import Grid from '../../components/Grid';
import Select from '../../components/Select';

import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import DeviceInfo from './components/DeviceInfo';
import DevicesInfoController from '../../redux/controllers/DevicesInfoController';
import CarInfo from './components/CarInfo';
import DeviceAlert from './components/DeviceAlert';

import './MainPage.scss';
import CarControl from './components/CarControl';
import EngineBlockButton from './components/EngineBlockButton';
import Typography from '../../components/Typography';
import Wrapper from '../../components/Wrapper';
import { ClickDelayProvider } from '../../components/ClickDelayLoader/ClickDelayLoader';
import EventAlert from '../../components/EventAlert';
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const { t } = useTranslation();

  const {
    list: devices,
    info,
    curDeviceId,
    alertMessage,
    bottomAlertMessage,
  } = useSelector(({ devicesInfo }: IGlobalState) => devicesInfo);

  const onChangeDevice = (value: string) => {
    DevicesInfoController.setCurDevice(value);
  };

  return (
    <ClickDelayProvider>
      <Grid
        className='MainPage'
        component={Wrapper}
        container
        direction='column'
      >
        <Grid container justify='center'>
          <Select
            icon='mini'
            onChange={onChangeDevice}
            options={devices.map(item => ({
              label: item.name,
              value: item.id,
            }))}
            popoverProps={{
              style: { width: 288 },
            }}
            value={curDeviceId}
            variant='text'
          />
        </Grid>
        <DeviceInfo />
        <DeviceAlert message={alertMessage} />
        <EventAlert message={bottomAlertMessage} />
        <CarInfo />
        <EngineBlockButton />
        <CarControl />
        {info?.state?.doors.trunk && (
          <Grid className='MainPage-trunkStatus' container justify='center'>
            <Typography color='second' size={14}>
              {t('control.trunkOpen')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ClickDelayProvider>
  );
};

export default MainPage;
