import React from 'react';
import { Props } from './interface';
import Typography from '../../../../components/Typography';
import classNames from 'classnames';

import './DeviceAlert.scss';
import { useTranslation } from 'react-i18next';

const DeviceAlert = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('DeviceAlert', message && 'DeviceAlert-show')}>
      <Typography color='red' size={14}>
        {message && t(`events.${message}` as any)}
      </Typography>
    </div>
  );
};

export default DeviceAlert;
