import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import appReducer from './reducers';
import initialState from './initialState';
import { IGlobalState } from './types';
import { CLEAR_STORE } from './actionTypes';

const reducer = (state: IGlobalState, action: AnyAction) => {
  if (action.type === CLEAR_STORE) {
    // @ts-ignore
    state = undefined;
  }

  return appReducer(state, action);
};

const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
  collapsed: true,
  timestamp: true,
});

function configureStore(state: IGlobalState) {
  const enhancer = compose(applyMiddleware(thunkMiddleware, loggerMiddleware));
  // @ts-ignore
  return createStore(reducer, state, enhancer);
}

export const store = configureStore(initialState);
