import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TrackingController, {
  selectTracking,
} from '../../redux/controllers/TrackingController';
import Map from '../Map';
import { useSelector } from 'react-redux';
import TrackingMarker from '../TrackingMarker';
import { selectDeviceInfo } from '../../redux/controllers/DevicesInfoController';

import './TrackingMap.scss';
import Datepicker from '../Datepicker';
import { DatepickerOnChange } from '../Datepicker/interface';
import { Polyline } from 'react-leaflet';
import TrackingCluster from '../TrackingCluster';
import {
  EHistoryType,
  ITrackingHistory,
} from '../../resources/types/trackingTypes';
import { ETrackingMarkerType } from '../TrackingMarker/interface';
import { IconOptions } from 'leaflet';
import TrackingMapButtons from '../TrackingMapButtons';
import TrackingMapContext from '../TrackingMapContext';
import TrackingStatsDialog from '../../dialogs/TrackingStatsDialog';
import TrackingMapCenter from '../TrackingMapCenter';
import { selectTheme } from '../../redux/controllers/AppController';
import { setDayEnd, setDayStart } from '../../resources/functions';
import useTimeZone from '../../hooks/useTimeZone';

const historyIconOptions: Partial<IconOptions> = {
  iconSize: [14, 15],
  iconAnchor: [7, 15],
  popupAnchor: [2, -12],
};

const TrackingMap = () => {
  const deviceInfo = useSelector(selectDeviceInfo);
  const tracking = useSelector(selectTracking);
  const theme = useSelector(selectTheme);

  const mapAvailable = deviceInfo.info?.params?.app.map;

  const [showRoute, setShowRoute] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [points, setPoints] = useState<[number, number][]>([]);
  const [startPoint, setStartPoint] = useState<ITrackingHistory>();
  const [finishPoint, setFinishPoint] = useState<ITrackingHistory>();
  const [statsDialogOpen, setStatsDialogOpen] = useState(false);

  const setUtcDate = useTimeZone();

  const openDatepicker = useCallback(() => {
    setDatepickerOpen(true);
  }, []);

  const closeDatepicker = useCallback(() => {
    setDatepickerOpen(false);
  }, []);

  const handleDatepicker = useCallback<DatepickerOnChange>(nextDate => {
    setDate(nextDate);
  }, []);

  const openStatsDialog = useCallback(() => {
    setStatsDialogOpen(true);
  }, []);

  const closeStatsDialog = useCallback(() => {
    setStatsDialogOpen(false);
  }, []);

  useEffect(() => {
    if (mapAvailable) {
      TrackingController.getCurrent();
    }
  }, [mapAvailable]);

  useEffect(() => {
    if (date) {
      const from = setUtcDate(setDayStart(date));
      const to = setUtcDate(setDayEnd(date));

      if (from && to) {
        TrackingController.getExtended(from, to);
        TrackingController.getStats(from, to);
      }
    }
  }, [date, setUtcDate]);

  useEffect(() => {
    if (tracking.history) {
      let start: ITrackingHistory | undefined = undefined;
      let finish: ITrackingHistory | undefined = undefined;

      setPoints(
        tracking.history.map(item => {
          if (item.t === EHistoryType.start) {
            start = item;
          }
          if (item.t === EHistoryType.finish) {
            finish = item;
          }
          return [item.lat, item.lon];
        })
      );

      setStartPoint(start);
      setFinishPoint(finish);
    }
  }, [tracking.history]);

  const center = useMemo<[number, number] | undefined>(() => {
    const { lat, lon } = tracking.current || {};
    if (lat && lon) {
      return [lat, lon];
    }
  }, [tracking.current]);

  const renderHistory = useCallback(() => {
    return tracking.history?.map(item => {
      if (item.t === EHistoryType.finish || item.t === EHistoryType.start) {
        return null;
      }

      return (
        <TrackingMarker
          iconOptions={historyIconOptions}
          key={item.d}
          position={[item.lat, item.lon]}
          popupData={{
            speed: item.s,
            date: item.d,
            lat: item.lat,
            lon: item.lon,
            type: item.t,
          }}
        />
      );
    });
  }, [tracking.history]);

  const renderMarker = useCallback(
    (data?: ITrackingHistory, type?: ETrackingMarkerType) => {
      if (!data) {
        return null;
      }

      return (
        <TrackingMarker
          type={type}
          position={[data.lat, data.lon]}
          popupData={{
            speed: data.s,
            date: data.d,
            lat: data.lat,
            lon: data.lon,
            type: data.t,
          }}
        />
      );
    },
    []
  );

  const trackingMapContextValue = useMemo(
    () => ({
      center,
      showRoute,
      setShowRoute,
      openDatepicker,
      openStatsDialog,
    }),
    [center, showRoute, openDatepicker, openStatsDialog]
  );

  const polylinePathOptions = useMemo(
    () => ({ color: theme === 'light' ? '#EB353D' : '#e65100' }),
    [theme]
  );

  if (!center || !mapAvailable) return null;

  return (
    <Map center={center} className='TrackingMap'>
      <TrackingMapContext.Provider value={trackingMapContextValue}>
        <TrackingMapCenter center={center} />
        {!showRoute && <TrackingMarker position={center} />}
        {showRoute && (
          <>
            <Polyline pathOptions={polylinePathOptions} positions={points} />
            <TrackingCluster>{renderHistory()}</TrackingCluster>
            {renderMarker(startPoint, ETrackingMarkerType.start)}
            {renderMarker(finishPoint, ETrackingMarkerType.finish)}
          </>
        )}
        <TrackingMapButtons />
        <Datepicker
          open={datepickerOpen}
          onClose={closeDatepicker}
          date={date}
          onChange={handleDatepicker}
        />
        <TrackingStatsDialog
          open={statsDialogOpen}
          onClose={closeStatsDialog}
          date={date}
        />
      </TrackingMapContext.Provider>
    </Map>
  );
};

export default TrackingMap;
