import React, { useCallback, useState } from 'react';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components/Dialog';
import { DeviceCreatingSteps, Props } from './interface';
import Button from '../../components/Button';
import FormElement from '../../components/FormElement';
import Typography from '../../components/Typography';
import DevicesInfoController from '../../redux/controllers/DevicesInfoController';
import AlertController from '../../redux/controllers/AlertController';
import { useTranslation } from 'react-i18next';

const DeviceCreatingDialog = (props: Props) => {
  const { open, onClose } = props;

  const { t } = useTranslation();

  const [step, setStep] = useState(DeviceCreatingSteps.add);

  const [key, setKey] = useState('');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setKey(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (step === DeviceCreatingSteps.add) {
      DevicesInfoController.addDevice(key, () => {
        setKey('');
        setStep(DeviceCreatingSteps.confirm);
      });
    } else {
      DevicesInfoController.confirmDevice(key, () => {
        onClose();
        DevicesInfoController.getDevices();
        AlertController.show(t('myCars.deviceAdded'), 'success');
      });
    }
  }, [key, step, t]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography size={18} weight={700}>
          {step === DeviceCreatingSteps.add
            ? t('myCars.enterKey')
            : t('myCars.SMSSent')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormElement
          value={key}
          onChange={handleChange}
          fullWidth
          label={t('common.key')}
          placeholder={`${t('common.enter')} ${t('common.key')}`}
        />
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={handleSubmit}>
          {t('common.next')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceCreatingDialog;
