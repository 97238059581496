import React from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Props } from './interface';

import './Svg.scss';

const Svg = ({ className, rotate, ...props }: Props) => {
  return (
    // @ts-ignore
    <ReactSVG
      {...props}
      className={classNames('Svg', className)}
      style={{ ...props.style, transform: `rotate(${rotate}deg)` }}
    />
  );
};

export default Svg;
