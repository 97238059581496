import React, { useCallback, useState } from 'react';
import {
  TAutostartConditionValues,
  TAutostartIntervalValues,
  TAutostartTemperatureValues,
} from '../../resources/types/devices';
import {
  autostartIntervalValues,
  autostartTemperatureValues,
} from '../../resources/constVariables';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../redux/types';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import Typography from '../../components/Typography';
import ChevronDownIcon from '../../icons/ChevronDownIcon';

import './ConditionValue.scss';
import { TFunction, useTranslation } from 'react-i18next';
import DialogList from '../../components/DialogList';
import { DialogListItem } from '../../components/DialogList/interface';

const mapValuesToOptions = (
  t: TFunction,
  condition: TAutostartConditionValues,
  values: readonly number[]
): DialogListItem[] =>
  values.map(num => ({
    label: `${num} ${condition === 'time' ? `${t('common.h')}.` : 'С°'}`,
    value: String(num),
  }));

const getOptions = (
  t: TFunction<any>,
  condition: TAutostartConditionValues
): DialogListItem[] => {
  switch (condition) {
    case 'time':
      return mapValuesToOptions(t, condition, autostartIntervalValues);
    case 'internal':
    case 'external':
      return mapValuesToOptions(t, condition, autostartTemperatureValues);
    default:
      return [];
  }
};

interface Props {
  condition: TAutostartConditionValues;
}

const ConditionValue = ({ condition }: Props) => {
  const { t } = useTranslation();

  const getConditionLabel = useCallback(
    (condition: TAutostartConditionValues) => {
      switch (condition) {
        case 'time':
          return t('heatWorkTime.byTimeInterval');
        case 'internal':
          return t('heatWorkTime.byInternalSensor');
        case 'external':
          return t('heatWorkTime.byExternalSensor');
        default:
          return '';
      }
    },
    [t]
  );

  const getConditionValue = useCallback(
    (condition: TAutostartConditionValues, value = 0) => {
      switch (condition) {
        case 'time':
          return `${value} ${t('common.h')}.`;
        case 'external':
        case 'internal':
          return `${value} С°`;
      }
    },
    [t]
  );

  const value = useSelector(({ devicesInfo }: IGlobalState) => {
    switch (condition) {
      case 'time':
        return devicesInfo.info?.params?.autostart.interval;
      case 'internal':
      case 'external':
        return devicesInfo.info?.params?.autostart.temperature;
    }
  });

  const onChange = useCallback(
    (value: string) => {
      if (condition === 'time') {
        DeviceSettingsController.setAutostartInterval(
          Number(value) as TAutostartIntervalValues
        );
      } else if (condition === 'internal' || condition === 'external') {
        DeviceSettingsController.setAutostartTemperature(
          Number(value) as TAutostartTemperatureValues
        );
      }
    },
    [condition]
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const toggle = () => setDialogOpen(!dialogOpen);

  return (
    <div className='ConditionValue' onClick={toggle}>
      <Typography size={14} color='main'>
        {getConditionLabel(condition)}
      </Typography>
      <div className='ConditionValue-value'>
        <Typography size={14} color='third'>
          {getConditionValue(condition, value)}
        </Typography>
        <ChevronDownIcon />
      </div>
      <DialogList
        open={dialogOpen}
        onClose={toggle}
        items={getOptions(t, condition)}
        onChange={onChange}
      />
    </div>
  );
};

export default ConditionValue;
