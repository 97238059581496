import { AnyAction } from 'redux';
import initialState from '../initialState';
import {
  DISABLE_SECURE_LOADING,
  DISABLE_ENGINE_BLOCK_LOADING,
  DISABLE_FIND_CAR_LOADING,
  DISABLE_HEAT_LOADING,
  DISABLE_TRUNK_LOADING,
  ENABLE_SECURE_LOADING,
  ENABLE_ENGINE_BLOCK_LOADING,
  ENABLE_FIND_CAR_LOADING,
  ENABLE_HEAT_LOADING,
  ENABLE_TRUNK_LOADING,
  SET_CUR_DEVICE,
  ENABLE_ENGINE_LOADING,
  DISABLE_ENGINE_LOADING,
  ENABLE_DASHCAM_LOADING,
  DISABLE_DASHCAM_LOADING,
  ENABLE_SERVICE_LOADING,
  DISABLE_SERVICE_LOADING,
} from '../actionTypes';

export default function (state = initialState.loadings, action: AnyAction) {
  switch (action.type) {
    case ENABLE_ENGINE_LOADING:
      return { ...state, engine: true };
    case DISABLE_ENGINE_LOADING:
      return { ...state, engine: false };

    case ENABLE_ENGINE_BLOCK_LOADING:
      return { ...state, engineBlock: true };
    case DISABLE_ENGINE_BLOCK_LOADING:
      return { ...state, engineBlock: false };

    case ENABLE_TRUNK_LOADING:
      return { ...state, trunk: true };
    case DISABLE_TRUNK_LOADING:
      return { ...state, trunk: false };

    case ENABLE_HEAT_LOADING:
      return { ...state, heat: true };
    case DISABLE_HEAT_LOADING:
      return { ...state, heat: false };

    case ENABLE_FIND_CAR_LOADING:
      return { ...state, findCar: true };
    case DISABLE_FIND_CAR_LOADING:
      return { ...state, findCar: false };

    case ENABLE_SECURE_LOADING:
      return { ...state, secure: true };
    case DISABLE_SECURE_LOADING:
      return { ...state, secure: false };

    case ENABLE_DASHCAM_LOADING:
      return { ...state, dashcam: true };
    case DISABLE_DASHCAM_LOADING:
      return { ...state, dashcam: false };

    case ENABLE_SERVICE_LOADING:
      return { ...state, service: true };
    case DISABLE_SERVICE_LOADING:
      return { ...state, service: false };

    case SET_CUR_DEVICE:
      return initialState.loadings;

    default:
      return state;
  }
}
