import Controller from './Controller';
import axios from '../../lib/axios';
import {
  GET_DEVICE_EVENTS,
  GET_DEVICE_INFO,
  GET_DEVICE_SETTINGS,
  GET_DEVICES,
  SET_ALERT_MESSAGE,
  SET_BOTTOM_ALERT_MESSAGE,
  SET_CUR_DEVICE,
  SET_SCHEDULE_SETTINGS,
  UPDATE_DEVICE_INFO,
  UPDATE_SENSITIVITY,
  UPDATE_SOCKET_EVENTS,
} from '../actionTypes';
import AlertController from './AlertController';
import {
  IDeviceEventsQueryParams,
  IDeviceInfo,
  IDeviceSocketEvents,
  TSensitivity,
  TSensitivityValue,
} from '../../resources/types/devices';
import {
  debounce,
  handleDefaultErrors,
  setLocalStorage,
} from '../../resources/functions';
import { STORAGE_CURRENT_DEVICE } from '../../resources/storageVariables';
import { IGlobalState } from '../types';
import AppController from './AppController';
import i18next from '../../lib/i18next';

class DevicesInfoController extends Controller {
  setAlertMessage(message: string) {
    this.dispatch({ type: SET_ALERT_MESSAGE, payload: message });
  }

  setBottomAlertMessage(message: string) {
    this.dispatch({ type: SET_BOTTOM_ALERT_MESSAGE, payload: message });
  }

  setCurDevice(deviceId: number | string) {
    this.dispatch({
      type: SET_CUR_DEVICE,
      value: deviceId,
    });
    setLocalStorage(STORAGE_CURRENT_DEVICE, deviceId);
  }

  async getDevices() {
    try {
      const response = await axios.get('/devices/list');

      this.dispatch({
        type: GET_DEVICES,
        value: response.data,
      });

      if (!this.getState().devicesInfo.curDeviceId) {
        this.setCurDevice(response.data.data[0]?.id);
      }
    } catch ({ response }) {
      if (response.data) {
        switch (response.status) {
          case 401:
            AlertController.show(i18next.t('common.logInAgain'), 'error');
            break;

          case 500:
            AlertController.show(i18next.t('common.serverError'), 'error');
            break;

          default:
            AlertController.show(response.data.message, 'error');
        }
      } else {
        AlertController.show(i18next.t('common.error'), 'error');
      }
    }
  }

  async getDeviceInfo(deviceId: number | string) {
    try {
      const response = await axios.get('/devices/main', {
        params: {
          deviceId,
        },
      });

      this.dispatch({
        type: GET_DEVICE_INFO,
        value: response.data,
      });
      this.updateSocketEvents({ alarm: response.data.data.lastAlarm });
      this.dispatch({
        type: SET_SCHEDULE_SETTINGS,
        payload: response.data.data.scheduleSettings,
      });
      AppController.checkButtonsState();
    } catch ({ response }) {
      if (response) {
        switch (response.status) {
          case 401:
            AlertController.show(i18next.t('common.logInAgain'), 'error');
            break;

          case 404:
            AlertController.show(i18next.t('common.deviceNotFound'), 'error');
            break;

          case 500:
            AlertController.show(i18next.t('common.serverError'), 'error');
            break;

          default:
            AlertController.show(response.data.message, 'error');
        }
      } else {
        AlertController.show(i18next.t('common.error'), 'error');
      }

      return null;
    }
  }

  async getDeviceEvents(params?: IDeviceEventsQueryParams) {
    try {
      const response = await axios.get('/devices/events', {
        params: { ...params },
      });

      this.dispatch({
        type: GET_DEVICE_EVENTS,
        value: response.data,
      });
    } catch ({ response }) {
      if (response) {
        switch (response.status) {
          case 401:
            AlertController.show(i18next.t('common.logInAgain'), 'error');
            break;

          case 404:
            AlertController.show(i18next.t('common.deviceNotFound'), 'error');
            break;

          case 500:
            AlertController.show(i18next.t('common.serverError'), 'error');
            break;

          default:
            AlertController.show(response.data.message, 'error');
        }
      } else {
        AlertController.show(i18next.t('common.error'), 'error');
      }
    }
  }

  async getDeviceSettings(deviceId: number) {
    try {
      const response = await axios.get('/devices/main', {
        params: {
          deviceId,
        },
      });

      this.dispatch({
        type: GET_DEVICE_SETTINGS,
        value: response.data,
      });
    } catch ({ response }) {
      if (response) {
        switch (response.status) {
          case 401:
            AlertController.show(i18next.t('common.logInAgain'), 'error');
            break;

          case 404:
            AlertController.show(i18next.t('common.deviceNotFound'), 'error');
            break;

          case 500:
            AlertController.show(i18next.t('common.serverError'), 'error');
            break;

          default:
            AlertController.show(response.data.message, 'error');
        }
      } else {
        AlertController.show(i18next.t('common.error'), 'error');
      }
    }
  }

  updateDeviceInfo(payload: Partial<IDeviceInfo>) {
    this.dispatch({
      type: UPDATE_DEVICE_INFO,
      payload,
    });
  }

  updateSocketEvents(payload: Partial<IDeviceSocketEvents>) {
    this.dispatch({
      type: UPDATE_SOCKET_EVENTS,
      payload,
    });
  }

  updateSensitivityAsync = debounce(
    async (name: TSensitivityValue, sensitivity: TSensitivityValue) => {
      try {
        await axios.post(`/devices/params/sensitivity_${name}`, {
          deviceId: this.deviceId,
          sensitivity,
        });
      } catch (e) {
        handleDefaultErrors(e, () => {
          if (e.response.status === 408) {
            AlertController.show(e.response.data.message, 'error');
          }
        });
      }
    },
    1000
  );

  async updateSensitivity(name: TSensitivity, value: TSensitivityValue) {
    this.dispatch({
      type: UPDATE_SENSITIVITY,
      payload: { [name]: value },
    });
    await this.updateSensitivityAsync(name, value);
  }

  async addDevice(key: string, success: () => void) {
    try {
      await axios.post('/devices/add', { key });
      success();
    } catch (e) {
      handleDefaultErrors(e);
    }
  }

  async confirmDevice(confirmKey: string, success: () => void) {
    try {
      await axios.post('/devices/confirm', { confirmKey });
      success();
    } catch (e) {
      handleDefaultErrors(e);
    }
  }
}

export const selectDeviceInfo = (state: IGlobalState) => state.devicesInfo;

export default new DevicesInfoController();
