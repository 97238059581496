import React from 'react';
import { Props } from './interface';
import classNames from 'classnames';

import './Radio.scss';

const Radio = ({ label, checked, onChange, disabled, name }: Props) => {
  return (
    <label
      className={classNames(
        'Radio',
        checked && 'Radio-checked',
        disabled && 'Radio-disabled'
      )}
    >
      <div className='Radio-inputContainer'>
        <input type='radio' checked={checked} onChange={onChange} name={name} />
        <span className='Radio-checkMark' />
      </div>
      {label}
    </label>
  );
};

export default Radio;
