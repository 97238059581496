import React from 'react';
import { useReducer } from 'react';

export default function <S>(
  initialState: S
): [
  React.ReducerState<React.Reducer<S, any>>,
  React.Dispatch<React.ReducerAction<React.Reducer<any, any>>>
] {
  return useReducer(
    (state: S, update: Partial<S>) => ({ ...state, ...update }),
    initialState
  );
}
