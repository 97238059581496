import React from 'react';
import { DialogActionsProps } from './interface';
import classNames from 'classnames';

const DialogActions = ({ children, className }: DialogActionsProps) => {
  return (
    <div className={classNames('DialogActions', className)}>{children}</div>
  );
};

export default DialogActions;
