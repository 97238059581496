import React from 'react';
import { IGlobalState } from '../../redux/types';
import { useSelector } from 'react-redux';

import usePending from '../../hooks/usePending';
import DeviceSettingsController from '../../redux/controllers/DeviceSettingsController';
import { autostartTimeValues } from '../../resources/constVariables';
import SelectDialog from '../../components/SelectDialog';

import './TimeSelect.scss';
import { TAutostartTimeValues } from '../../resources/types/devices';
import { useTranslation } from 'react-i18next';

const selectTimeValue = (state: IGlobalState) =>
  state.devicesInfo.info?.params?.autostart.time;

const TimeSelect = () => {
  const time = useSelector(selectTimeValue);

  const { t } = useTranslation();

  const [pending, onFetch] = usePending((time: TAutostartTimeValues) =>
    DeviceSettingsController.setAutoStartDuration(time)
  );

  const value = time || 0;

  return (
    <SelectDialog
      label={`${t('heatWorkTime.engineWorkTime')} ${
        value ? `(${value} ${t('common.min')})` : ''
      }`}
      items={autostartTimeValues.map(item => ({
        label: `${item} ${t('common.min')}.`,
        value: item,
      }))}
      onChange={onFetch}
      disabled={pending}
    />
  );
};

export default TimeSelect;
