import { AnyAction } from 'redux';
import { IGlobalState } from '../types';
import { store } from '../createStore';

export default class Controller {
  protected getState(): IGlobalState {
    return store.getState();
  }

  protected dispatch(action: AnyAction) {
    store.dispatch(action);
  }

  protected get deviceId() {
    return String(this.getState().devicesInfo.curDeviceId || '');
  }
}
