import React from 'react';
import classNames from 'classnames';
import { Props } from './interface';

import './Paper.scss';

const Paper = ({ children, className, innerRef, ...props }: Props) => {
  const cn = classNames('Paper', className);

  return (
    <div {...props} ref={innerRef} className={cn}>
      {children}
    </div>
  );
};

export default Paper;
