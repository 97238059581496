import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { shallowEqual, useSelector } from 'react-redux';

import AppBar from './components/AppBar';
import Grid from './components/Grid';
import NavBar from './components/NavBar';

import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';

import './scss/App.scss';
import Routes from './components/Routes';
import { IGlobalState } from './redux/types';
import useReduxAlert from './hooks/useReduxAlert';
import AppController, { selectTheme } from './redux/controllers/AppController';
import PasswordRecovery from './pages/PasswordRecovery';
import DevicesInfoController from './redux/controllers/DevicesInfoController';
import TrackingMap from './components/TrackingMap';
import i18n from './lib/i18next';
import moment from 'moment';
import { EMomentLocales } from './resources/types/app';

function App() {
  useReduxAlert();

  const { auth, deviceId, isInit, language } = useSelector(
    (state: IGlobalState) => ({
      auth: state.user.auth,
      deviceId: state.devicesInfo.curDeviceId,
      isInit: state.app.isInit,
      language: state.user.settings?.language,
    }),
    shallowEqual
  );
  const theme = useSelector(selectTheme);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      const locale = EMomentLocales[language];
      import(`moment/locale/${locale}`).then(() => {
        moment.locale(locale);
      });
    }
  }, [language]);

  useEffect(() => {
    if (deviceId) {
      DevicesInfoController.getDeviceInfo(deviceId);
    }
  }, [deviceId]);

  useEffect(() => {
    if (auth?.accessToken) {
      if (!isInit) {
        AppController.init();
      }
    } else {
      AppController.close();
    }
  }, [auth, isInit]);

  useEffect(() => {
    document.documentElement.setAttribute(
      'theme',
      !auth?.accessToken ? 'light' : theme
    );
  }, [theme, auth]);

  return (
    <Grid className='App' container>
      {auth ? (
        <>
          <Grid
            className='App-content'
            container
            direction='column'
            justify='space-between'
          >
            <AppBar />
            <main>
              <Routes />
            </main>
            <NavBar />
          </Grid>
          <TrackingMap />
        </>
      ) : (
        <Switch>
          <Route component={RegistrationPage} path='/registration' />
          <Route component={PasswordRecovery} path='/recovery' />
          <Route component={LoginPage} path='*' />
        </Switch>
      )}
    </Grid>
  );
}

export default hot(module)(App);
