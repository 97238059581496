import { AnyAction } from 'redux';
import initialState from '../initialState';
import {
  CLEAR_STORE,
  GET_DEVICE_EVENTS,
  GET_DEVICE_INFO,
  GET_DEVICE_SETTINGS,
  GET_DEVICES,
  SET_PHONE_SETTINGS,
  SET_AUTOSTART_PARAMS,
  SET_CUR_DEVICE,
  SET_TRUNK,
  UPDATE_CAN_STATE,
  UPDATE_DEVICE_INFO,
  UPDATE_PARAMS,
  UPDATE_SENSITIVITY,
  UPDATE_SOCKET_EVENTS,
  UPDATE_STATE,
  SET_ALERT_MESSAGE,
  SET_BOTTOM_ALERT_MESSAGE,
  SET_CURRENT_COORDS,
} from '../actionTypes';

export default function (state = initialState.devicesInfo, action: AnyAction) {
  switch (action.type) {
    case SET_CUR_DEVICE:
      return {
        ...state,
        curDeviceId: action.value,
      };

    case GET_DEVICES:
      return {
        ...state,
        list: action.value.data,
      };

    case GET_DEVICE_INFO:
      return {
        ...state,
        info: action.value.data,
      };

    case GET_DEVICE_EVENTS:
      return {
        ...state,
        events: action.value,
      };

    case GET_DEVICE_SETTINGS:
      return {
        ...state,
        settings: action.value.data,
      };

    case UPDATE_PARAMS:
      return {
        ...state,
        info: {
          ...state.info,
          params: {
            ...state.info?.params,
            ...action.value,
          },
        },
      };

    case UPDATE_STATE:
      return {
        ...state,
        info: {
          ...state.info,
          state: {
            ...state.info?.state,
            ...action.value,
          },
        },
      };

    case UPDATE_CAN_STATE:
      return {
        ...state,
        info: {
          ...state.info,
          canState: {
            ...state.info?.canState,
            ...action.value,
          },
        },
      };

    case SET_TRUNK:
      return {
        ...state,
        info: {
          ...state.info,
          state: {
            ...state.info?.state,
            doors: {
              ...state.info?.state?.doors,
              trunk: action.value,
            },
          },
        },
      };

    case SET_AUTOSTART_PARAMS:
      return {
        ...state,
        info: {
          ...state.info,
          params: {
            ...state.info?.params,
            autostart: {
              ...state.info?.params?.autostart,
              ...action.params,
            },
          },
        },
      };

    case UPDATE_SOCKET_EVENTS:
      return {
        ...state,
        socketEvents: {
          ...state.socketEvents,
          ...action.payload,
        },
      };

    case UPDATE_DEVICE_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
      };

    case UPDATE_SENSITIVITY:
      return {
        ...state,
        info: {
          ...state.info,
          params: {
            ...state.info?.params,
            sensitivity: {
              ...state.info?.params?.sensitivity,
              ...action.payload,
            },
          },
        },
      };

    case SET_PHONE_SETTINGS:
      return {
        ...state,
        phones: {
          ...state.phones,
          ...action.payload.data,
        },
      };

    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.payload,
      };

    case SET_BOTTOM_ALERT_MESSAGE:
      return {
        ...state,
        bottomAlertMessage: action.payload,
      };

    case SET_CURRENT_COORDS:
      return {
        ...state,
        info: {
          ...state.info,
          coordEvent: {
            ...state.info?.coordEvent,
            speed: action.payload.data.speed,
          },
        },
      };

    case CLEAR_STORE:
      return {
        ...state,
        curDeviceId: null,
      };

    default:
      return state;
  }
}
