import React, { useCallback, useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import SettingsTitle from '../../components/SettingsTitle';
import { useTranslation } from 'react-i18next';
import { days } from '../../resources/constVariables';
import ScheduleDay from '../../components/ScheduleDay';
import Grid from '../../components/Grid';
import './SchedulePage.scss';
import Button from '../../components/Button';
import ScheduleController from '../../redux/controllers/ScheduleController';
import usePending from '../../hooks/usePending';

const SchedulePage = () => {
  const { t } = useTranslation();

  const [isSettingsChanged, setIsSettingsChanged] = useState(false);

  const handleChangeSchedule = useCallback(() => {
    setIsSettingsChanged(true);
  }, []);

  const [isPending, updateSettings] = usePending(() =>
    ScheduleController.updateScheduleSettings()
  );

  useEffect(() => {
    if (!isPending) {
      setIsSettingsChanged(false);
    }
  }, [isPending]);

  return (
    <Grid container direction='column' className='SchedulePage'>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('schedule.title')}</SettingsTitle>
      </Wrapper>
      <Grid container direction='column' className='SchedulePage-schedule'>
        {days.map(day => (
          <ScheduleDay day={day} key={day} onChange={handleChangeSchedule} />
        ))}
      </Grid>
      {isSettingsChanged && (
        <Button
          className='SchedulePage-saveButton'
          onClick={updateSettings}
          loading={isPending}
          disabled={isPending}
        >
          {t('common.save')}
        </Button>
      )}
    </Grid>
  );
};

export default SchedulePage;
