import React from 'react';
import Typography from '../Typography';
import { Props } from './interface';

import './SettingsTitle.scss';

const SettingsTitle = ({ children }: Props) => {
  return (
    <Typography
      className='SettingsTitle'
      color='main'
      size={18}
      weight={500}
      component='h1'
    >
      {children}
    </Typography>
  );
};

export default SettingsTitle;
