export type TButtons =
  | 'trunk'
  | 'block'
  | 'search'
  | 'heat'
  | 'engine'
  | 'dashcam';

export enum ESpeed {
  CAN = 'CAN',
  GPS = 'GPS',
}

export interface IButtonsSettings {
  main: TButtons[];
  small: TButtons[];
  unused: TButtons[];
}

export type TButtonGroups = 'main' | 'small' | 'unused';

export interface IButton {
  name: TButtons;
  group: TButtonGroups;
}

export type TTheme = 'light' | 'dark';

export enum EMomentLocales {
  ru = 'ru',
  en = 'en',
  ua = 'uk',
  ee = 'et',
}
