export interface ITrackingCurrent {
  deviceId: string;
  direction: string;
  heading: number;
  height: number;
  lat: number;
  lon: number;
  satellites: number;
  speed: number;
}

export enum EHistoryType {
  start = 's',
  normal = 'n',
  parking = 'p',
  finish = 'f',
}

export interface ITrackingHistory {
  // date
  d: string;
  lat: number;
  lon: number;
  // speed
  s: 5;
  /* "s" - стартовая точка
     "n" - нормальное движение
     "p" - машина на парковке
     "f" - финиш */
  t: EHistoryType;
}

export interface ITrackingStats {
  averageSpeed: number;
  distance: number;
  maxSpeed: number;
  movingTime: number;
  totalTime: number;
}
