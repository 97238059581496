import React, { useState } from 'react';
import Grid from '../../components/Grid';
import SettingsTitle from '../../components/SettingsTitle';
import FormElement from '../../components/FormElement';
import { IGlobalState } from '../../redux/types';
import { useSelector } from 'react-redux';
import Button from '../../components/Button';
import Wrapper from '../../components/Wrapper';
import './CommonInfoPage.scss';
import ChangePasswordDialog from '../../dialogs/ChangePasswordDialog';
import { useTranslation } from 'react-i18next';

const selectInfo = (state: IGlobalState) => state.user.info;

const CommonInfoPage = () => {
  const info = useSelector(selectInfo);

  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Grid container direction='column' className='CommonInfoPage'>
      <Wrapper withHorizontalPadding>
        <SettingsTitle>{t('settings.commonInfo')}</SettingsTitle>
        <FormElement
          label={t('common.login')}
          value={info?.login}
          readOnly
          fullWidth
        />
        <FormElement
          label={t('common.email')}
          value={info?.email}
          readOnly
          fullWidth
        />
        <Button
          className='CommonInfoPage-button'
          variant='outlined'
          color='second'
          fullWidth
          onClick={() => setDialogOpen(true)}
        >
          {t('common.changePassword')}
        </Button>
      </Wrapper>
      <ChangePasswordDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Grid>
  );
};

export default CommonInfoPage;
