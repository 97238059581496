import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { Props } from './interface';

const TrackingMapCenter = ({ center }: Props) => {
  const map = useMap();

  useEffect(() => {
    if (center && map) {
      map.setView(center, map.getZoom());
    }
  }, [center]);

  return null;
};

export default TrackingMapCenter;
