import React from 'react';
import { THistoryEvents } from '../../resources/types/devices';
import classNames from 'classnames';

import './Indicator.scss';

interface Props {
  type: THistoryEvents;
}

const Indicator = ({ type }: Props) => {
  return <div className={classNames('Indicator', `Indicator-type-${type}`)} />;
};

export default Indicator;
