import { useSelector } from 'react-redux';
import { IGlobalState } from '../redux/types';
import { useCallback } from 'react';
import moment, { Moment } from 'moment';

const useTimeZone = () => {
  const utc = useSelector(
    (state: IGlobalState) => state.devicesInfo.info?.params?.utc
  );

  return useCallback(
    (date?: Moment | Date | null | string) => {
      return utc !== undefined
        ? moment(date).utcOffset(utc).toISOString(true)
        : undefined;
    },
    [utc]
  );
};

export default useTimeZone;
